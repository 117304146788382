import apiClient from "config/api";

const path = "/admin/auth";

class AuthService {
  login = (payload, onSuccess, onError) => {
    return apiClient
      .post(`${path}/login`, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };
}

export default new AuthService();
