import apiClient from "config/api";
const path = "/admin/users";

class AccountService {
  fetchRoles = (onSuccess, onError) => {
    return apiClient
      .get(`${path}/roles`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  fetchUsers = (enabled, page, limit, onSuccess, onError) => {
    return apiClient
      .get(`${path}?enabled=${enabled}&page=${page}&limit=${limit}`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  searchUsers = (keyword, enabled, page, limit, onSuccess, onError) => {
    return apiClient
      .get(`${path}/search?keyword=${keyword}&enabled=${enabled}&page=${page}&limit=${limit}`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  createUser = (payload, onSuccess, onError) => {
    return apiClient
      .post(`${path}/create`, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  updateInfo = (id, payload, onSuccess, onError) => {
    return apiClient
      .put(`${path}/${id}/info`, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  disableAccount = (id, onSuccess, onError) => {
    return apiClient
      .patch(`${path}/${id}/enable?enable=false`, {})
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  resetPassword = (id, onSuccess, onError) => {
    return apiClient
      .post(`${path}/${id}/password/reset`, {})
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };
}

export default new AccountService();
