import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import MDContentModal from "components/MDModal/MDContentModal";

import UtilService from "services/UtilService";

import { useAuth } from "context/AuthContext";
import { setPageError, useMaterialUIController } from "context/UIContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
};

function QRCode() {
  const { useSessionExpired } = useAuth();
  const [controller, dispatch] = useMaterialUIController();
  const [data, setData] = useState();
  const [downloadLink, setDownloadLink] = useState();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) fetchQRCode();
  }, [open]);

  const fetchQRCode = () => {
    UtilService.fetchQRCode(
      (response) => {
        if (response.data.success) {
          const result = response.data.result;
          setData(result.qrcode);
          setDownloadLink(result.download_link);
        }
      },
      (error) => {
        if (error?.response?.status == 401) useSessionExpired();
        else setPageError(dispatch, "An error occurred, please try again later");
      }
    );
  };

  const Button = () => (
    <MDButton variant="gradient" color="light" size={"small"} onClick={() => setOpen(true)}>
      Activate
    </MDButton>
  );

  return (
    <div>
      <MDContentModal
        isOpen={open}
        setClose={() => setOpen(false)}
        button={<Button handleOpen={() => setOpen(true)} />}
      >
        <Fade in={open}>
          <Card sx={style}>
            {data && (
              <MDBox>
                <MDBox variant="gradient" bgColor="dark" p={1} textAlign="center">
                  <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                    Device Activation
                  </MDTypography>
                  <MDTypography display="block" variant="h6" color="white" my={1}>
                    Scan QR Code to activate device
                  </MDTypography>
                </MDBox>
                <MDBox pt={1} pb={1} px={1}>
                  <MDBox mb={0}>
                    <CardMedia
                      src={`data:image/png;base64,${data}`}
                      component="img"
                      sx={{
                        maxWidth: "100%",
                        minWidth: "100%",
                        margin: 0,
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </MDBox>
                  <Grid container spacing={1} mt={1} mb={1}>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDButton
                        variant="gradient"
                        color="light"
                        fullWidth
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6} textAlign={"right"}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        fullWidth
                        onClick={() => window.open(downloadLink, "_blank")}
                      >
                        Download
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            )}
          </Card>
        </Fade>
      </MDContentModal>
    </div>
  );
}

export default QRCode;
