import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

const AuthContext = createContext();

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState();
  const [userHome] = useState(() => {
    return localStorage.getItem("user_home");
  });
  const [authorization] = useState(() => {
    return localStorage.getItem("authorization");
  });
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) setUser(user);
    return !isEmpty(user);
  });
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const useLogin = (data) => {
    setIsAuthenticated(true);
    setIsSessionExpired(false);
    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("authorization", data.token);
    let userHome;
    if (isEmpty(data?.institution_id)) userHome = "/dashboard";
    else userHome = `/institutions/${data.institution_id}/monitor`;
    localStorage.setItem("user_home", userHome);
    navigate(userHome);
  };

  const useLogout = () => {
    useSessionExpired();
    setIsAuthenticated(false);
    navigate("/auth/login");
  };

  const useSessionExpired = () => {
    localStorage.setItem("user", null);
    localStorage.setItem("user_home", null);
    localStorage.setItem("token", null);
    setIsSessionExpired(true);
  };

  const contextValue = {
    user,
    userHome,
    isAuthenticated,
    isSessionExpired,
    authorization,
    useLogin,
    useLogout,
    useSessionExpired,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export { useAuth, AuthProvider };
