import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

import { useAuth } from "context/AuthContext";
import DeviceService from "services/DeviceService";
import { setPageError, useMaterialUIController } from "context/UIContext";

function Monitor({ institutionId }) {
  const { useSessionExpired } = useAuth();
  const [controller, dispatch] = useMaterialUIController();

  const [chartData, setChartData] = useState({});
  const [updatedAt, setUpdatedAt] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchMonitor();
  }, []);

  const fetchMonitor = () => {
    setIsLoading(true);
    DeviceService.fetchInstitutionMonitor(
      institutionId,
      (response) => {
        setIsLoading(false);
        if (response.data.success) {
          const result = response.data.result;
          setUpdatedAt(result.updated_at);
          let data = {
            labels: result.labels,
            datasets: { label: "Apps", data: result.counts },
          };
          setChartData(data);
        } else setPageError(dispatch, response.data.message);
      },
      (error) => {
        setIsLoading(false);
        if (error?.response?.status == 401) useSessionExpired();
        else setPageError(dispatch, "An error occurred, please try again later");
      }
    );
  };

  return (
    <MDBox mt={6}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          {isLoading ? (
            <MDBox display="flex" justifyContent="center" alignItems="center" sx={{ height: 200 }}>
              <CircularProgress color="inherit" size={20} />
            </MDBox>
          ) : (
            <MDBox mb={3}>
              <ReportsBarChart
                color="secondary"
                title="Apps Usage"
                description={<></>}
                date={updatedAt ? `Updated ${updatedAt}` : "No updates"}
                chart={chartData}
              />
            </MDBox>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

Monitor.propTypes = {
  institutionId: PropTypes.string.isRequired,
};

export default Monitor;
