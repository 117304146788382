/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import InfoCard from "layouts/institution/tabs/manager/components/InfoCard";
import LocationCard from "layouts/institution/tabs/manager/components/LocationCard";
import AccountsCard from "layouts/institution/tabs/manager/components/AccountsCard";

import Util from "helper/Util";

function Info({ data, setData }) {
  return (
    <MDBox mt={3} mb={1}>
      <Card
        sx={{
          position: "relative",
          mt: 0,
          mx: 0,
          py: 3,
          px: 2,
        }}
      >
        <MDBox mt={0} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <InfoCard
                title="Basic Info"
                info={{
                  id: data.id,
                  name: data.name,
                  enrollment_code: data.enrollment_code,
                  activated_devices: Util.formatNumber(data.activated_devices, 0),
                  allocated_devices: Util.formatNumber(data.allocated_devices, 0),
                }}
                updateSource={setData}
                shadow={false}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1, position: "absolute" }} />
              <LocationCard
                title="Location"
                info={{
                  id: data.id,
                  geo_radius: `${
                    data.radius
                      ? data.radius > 1000
                        ? Util.formatNumber(Math.floor(data.radius / 1000), 0) + `KM`
                        : Util.formatNumber(Math.ceil(data.radius), 2) + `M`
                      : ``
                  }`,
                  latitude:
                    data?.position?.lat &&
                    Number(data?.position?.lat && data?.position?.lat.toFixed(6)),
                  longitude:
                    data?.position?.lng &&
                    Number(data?.position?.lng && data?.position?.lng.toFixed(6)),
                  address: data.address,
                }}
                data={data}
                updateSource={setData}
                shadow={false}
              />
            </Grid>
            <Grid item xs={12} xl={4}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1, position: "absolute" }} />
              <MDBox ml={0}>
                <AccountsCard
                  title="Accounts"
                  id={data.id}
                  list={data.accounts}
                  shadow={false}
                  updateSource={setData}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}

Info.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

export default Info;
