class Util {
  static isEmpty = (value) => {
    return value == null || (typeof value === "string" && value.trim().length === 0);
  };

  static formatString = (str, ...args) => args.reduce((s, v) => s.replace("%s", v), str);

  static formatBytes = (bytes, decimals) => {
    if (bytes == 0) return "0 Bytes";
    var k = 1024,
      dm = decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  static byteCount = (bytes, unit) => {
    if (bytes < ((unit = unit) || 1000)) return bytes + " B";
    var exp = Math.floor(Math.log(bytes) / Math.log(unit));
    var pre =
      " " +
      (unit === 1000 ? "kMGTPE" : "KMGTPE").charAt(exp - 1) +
      (unit === 1000 ? "" : "i") +
      "B";
    return (bytes / Math.pow(unit, exp)).toFixed(1) + pre;
  };

  static formatMilliSeconds = (milliSeconds) => {
    let second = milliSeconds / 1000;
    let result;
    if (second < 60) {
      result = `${Math.trunc(second)}s`;
    } else if (second < 60 * 60) {
      result = `${Math.trunc(second / 60)}m ${Math.trunc(second % 60)}`;
    } else {
      result = `${Math.trunc(second / 3600)}h ${Math.trunc((second % 3600) / 60)}m 
      ${Math.trunc((second % 3600) % 60)}s`;
    }
    return result;
  };

  static formatNumber = (value, max) => {
    const nf = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: max !== undefined ? max : 2,
      maximumFractionDigits: max !== undefined ? max : 2,
    });
    return nf.format(value);
  };
}

export default Util;
