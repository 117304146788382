import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDConfirmModal from "components/MDModal/MDConfirmModal";

import InstitutionService from "services/InstitutionService";

import { useAuth } from "context/AuthContext";

function Options({ id, index, action, onComplete }) {
  const { useSessionExpired } = useAuth();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);

  const title = (action === "close" ? "Close" : "Open") + " Institution";
  const text = "Are you sure you want to continue?";

  const confirmAction = () => {
    setError();
    setSubmitting(true);
    if (action === "close") InstitutionService.deleteInstitution(id, onSuccess, onError);
    else InstitutionService.changeStatus(id, "OPEN", onSuccess, onError);
  };

  const onSuccess = (response) => {
    setSubmitting(false);
    if (response.data.success) {
      onComplete(index);
      setOpen(false);
    } else setError(response.data.message);
  };

  const onError = (error) => {
    setSubmitting(false);
    if (error?.response?.status == 401) {
      useSessionExpired();
    } else if (error?.response?.status >= 400 && error?.response?.status < 500) {
      setError(error?.response?.data?.message);
    } else setError("An error occured, please try again later");
  };

  const Button = ({ action }) => (
    <MDButton
      variant="gradient"
      color={action === "close" ? "error" : "secondary"}
      size={"small"}
      onClick={() => setOpen(true)}
    >
      <Icon fontSize="inherit" color="inherit">
        {action === "close" ? "delete" : "restore"}
      </Icon>
    </MDButton>
  );

  return (
    <div>
      <MDConfirmModal
        title={title}
        text={text}
        error={error}
        isOpen={open}
        submitting={submitting}
        onConfirm={confirmAction}
        onComplete={onComplete}
        setClose={() => setOpen(false)}
        button={<Button action={action} handleOpen={() => setOpen(true)} />}
      />
    </div>
  );
}

Options.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
};

export default Options;
