import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-routers components
import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DataTable from "examples/Tables/DataTable";

import breakpoints from "assets/theme/base/breakpoints";

import QRCode from "layouts/institution/tabs/devices/components/Modals/QRCode";
import Form from "layouts/institution/tabs/devices/components/Modals/Form";

// Data
import devicesTableData from "layouts/institution/tabs/devices/data/devicesTableData";

import DeviceService from "services/DeviceService";
import { useAuth } from "context/AuthContext";
import { setPageError, useMaterialUIController } from "context/UIContext";

const statusMap = {
  ACTIVE: 0,
  PENDING: 1,
  INACTIVE: 2,
  EXPIRED: 3,
};

function Devices({ institutionId }) {
  const BASE_PATH = `/institutions/${institutionId}`;

  const { useSessionExpired } = useAuth();
  const [controller, dispatch] = useMaterialUIController();

  const { status } = useParams();
  const navigate = useNavigate();
  const match = /(active|inactive|pending|expired)/.test(status);

  const statusValue = status && match ? status.toUpperCase() : "ACTIVE";

  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [dataLimit, setDataLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabIndex, setTabIndex] = useState(
    Object.keys(statusMap).includes(statusValue) ? statusMap[statusValue] : 0
  );

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    if (!statusValue) navigate(`${BASE_PATH}/devices/active`);
    setTabIndex(Object.keys(statusMap).includes(statusValue) ? statusMap[statusValue] : 0);
  }, [statusValue]);

  useEffect(() => {
    setCurrentPage(0);
    if (isSearching) {
      searchDevices(searchKeyword, 0, dataLimit);
    } else fetchDevices(0, dataLimit);
  }, [tabIndex]);

  useEffect(() => {
    if (isLoaded) {
      if (isSearching) {
        searchDevices(searchKeyword, currentPage, dataLimit);
      } else fetchDevices(currentPage, dataLimit);
    }
  }, [currentPage, isSearching]);

  useEffect(() => {
    if (isLoaded) {
      if (isSearching) {
        searchDevices(searchKeyword, 0, dataLimit);
      } else fetchDevices(0, dataLimit);
    }
  }, [dataLimit]);

  useEffect(() => {
    if (isLoaded) {
      const { rows, columns } = devicesTableData(institutionId, data, updateData, deleteData);
      setColumns(columns);
      setRows(rows);
    }
  }, [data]);

  useEffect(() => {
    if (isLoaded) {
      if (searchKeyword && searchKeyword.trim().length >= 3) {
        setCurrentPage(0);
        setIsSearching(true);
      } else if (searchKeyword === undefined) setIsSearching(false);
    }
  }, [searchKeyword]);

  const fetchDevices = (page, limit) => {
    setIsLoading(true);
    DeviceService.fetchDevices(
      institutionId,
      statusValue,
      page,
      limit,
      onFetchSuccess,
      onFetchError
    );
  };

  const searchDevices = (keyword, page, limit) => {
    DeviceService.searchDevices(
      institutionId,
      keyword,
      statusValue,
      page,
      limit,
      onFetchSuccess,
      onFetchError
    );
  };

  const onFetchSuccess = (response) => {
    setIsLoaded(true);
    setIsLoading(false);
    if (response.data.success) {
      setData(response.data.result.devices);
      setTotalCount(response.data.result.total);
    } else setPageError(dispatch, response.data.message);
  };

  const onFetchError = (error) => {
    if (error?.response?.status == 401) useSessionExpired();
    else setPageError(dispatch, "An error occurred, please try again later");
  };

  const addData = (item) => {
    if (tabIndex === 1) {
      const newData = [item, ...data];
      setData(newData);
    }
  };

  const updateData = (index, item) => {
    const newData = [...data];
    newData[index] = item;
    setData(newData);
  };

  const deleteData = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const handleSetTabIndex = (event, newValue) => {
    setTabIndex(statusMap[newValue.toUpperCase()]);
  };

  return (
    <MDBox mt={2} mb={1}>
      <Grid container spacing={3} alignItems="center" pb={2}>
        <Grid item xs={6} md={6} lg={1.3}>
          <Form institutionId={institutionId} addData={(d) => addData(d)} />
        </Grid>
        <Grid item xs={6} md={6} lg={1.3}>
          <QRCode />
        </Grid>
        <Grid item xs={12} md={12} lg={6} sx={{ ml: "auto" }}>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={status && match ? status : "active"}
              onChange={handleSetTabIndex}
            >
              <Tab
                label="Active"
                value="active"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    phonelink_ring
                  </Icon>
                }
                onClick={() => navigate(`${BASE_PATH}/devices/active`)}
              />
              <Tab
                label="Pending"
                value="pending"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    device_unknown
                  </Icon>
                }
                onClick={() => navigate(`${BASE_PATH}/devices/pending`)}
              />
              <Tab
                label="Inactive"
                value="inactive"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    mobile_off
                  </Icon>
                }
                onClick={() => navigate(`${BASE_PATH}/devices/inactive`)}
              />
              <Tab
                label="Expired"
                value="expired"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    phonelink_erase
                  </Icon>
                }
                onClick={() => navigate(`${BASE_PATH}/devices/expired`)}
              />
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                entriesPerPage={{
                  defaultValue: dataLimit,
                  entries: [10, 20, 30, 40],
                }}
                pagination={{
                  variant: "gradient",
                  color: "secondary",
                }}
                showTotalEntries={true}
                canSearch={true}
                isServerSide={true}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalCount={totalCount}
                setDataLimit={setDataLimit}
                setSearchKeyword={setSearchKeyword}
                isLoading={isLoading}
                searchPlaceholder={"Search IDs, Users..."}
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

Devices.propTypes = {
  institutionId: PropTypes.string,
};

export default Devices;
