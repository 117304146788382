/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Form from "layouts/institution/components/Modals/MapForm";

function LocationCard({ title, info, data, updateSource, shadow }) {
  const labels = [];
  const values = [];

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el, i) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      let newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);
      newElement = newElement.replace("_", " ");
      labels.push(newElement);
    } else {
      const newElement = el.replace("_", " ");
      labels.push(newElement);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el, i) => values.push(el));

  // Render the card info items
  const renderItems = labels.map(
    (label, key) =>
      label.toLowerCase() !== "id" && (
        <MDBox key={label} display="flex" py={1} pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {label}:&nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{values[key]}
          </MDTypography>
        </MDBox>
      )
  );

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        <Form data={data} onComplete={updateSource} />
      </MDBox>
      <MDBox p={2}>
        <MDBox>{renderItems}</MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the InfoCard
LocationCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the InfoCard
LocationCard.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.any).isRequired,
  shadow: PropTypes.bool,
  data: PropTypes.object,
  updateSource: PropTypes.func.isRequired,
};

export default LocationCard;
