import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import MDContentModal from "components/MDModal/MDContentModal";

import DeviceService from "services/DeviceService";
import { useAuth } from "context/AuthContext";
import { isEmpty } from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
};

function Form({ institutionId, data, index, addData, updateData }) {
  const { useSessionExpired } = useAuth();

  const [open, setOpen] = useState(false);
  const [isUpdate] = useState(data !== null && data !== undefined);
  const deviceId = data && data.id;
  const [name, setName] = useState(data ? data.name : "");
  const [nameError, setNameError] = useState();
  const [brand, setBrand] = useState(data ? data.brand : "");
  const [brandError, setBrandError] = useState();
  const [model, setModel] = useState(data ? data.model : "");
  const [modelError, setModelError] = useState();
  const [message, setMessage] = useState();
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);

  const submitForm = () => {
    resetErrors();
    let hasError = false;
    if (isEmpty(name)) {
      hasError = true;
      setNameError("Name is required");
    }
    if (isEmpty(brand)) {
      hasError = true;
      setBrandError("Brand is required");
    }
    if (isEmpty(model)) {
      hasError = true;
      setModelError("Model is required");
    }

    if (!hasError) {
      setSubmitting(true);
      const payload = { name, brand, model };
      if (isUpdate) {
        DeviceService.updateDeviceInfo(institutionId, deviceId, payload, onSuccess, onError);
      } else DeviceService.registerDevice(institutionId, payload, onSuccess, onError);
    }
  };

  const onSuccess = (response) => {
    setSubmitting(false);
    if (response.data.success) {
      setMessage(response.data.message);
      const result = response.data.result;
      if (isUpdate) {
        updateData(index, result);
        handleClose();
      } else {
        addData(result);
        resetForm();
      }
    } else setError(response.data.message);
  };

  const onError = (error) => {
    setSubmitting(false);
    if (error?.response?.status == 401) {
      useSessionExpired();
    } else if (error?.response?.status >= 400 && error?.response?.status < 500) {
      setError(error?.response?.data?.message);
      if (error?.response?.status === 400) {
        const errors = error?.response?.data?.errors;
        if (errors) {
          if (inputsMap.name in errors) setNameError(errors[inputsMap.name]);
        }
      }
    } else {
      setError("An error occured");
    }
  };

  const resetForm = () => {
    setBrand("");
    setModel("");
    setName("");
  };

  const handleOpen = () => setOpen(true);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    if (reason === "escapeKeyDown") {
      return false;
    }
    resetErrors();
    setOpen(false);
  };

  const resetErrors = () => {
    setError();
    setMessage();
    setNameError();
    setBrandError();
    setModelError();
  };

  const Button = () =>
    isUpdate ? (
      <MDButton variant="gradient" color="light" size={"small"} onClick={handleOpen}>
        <Icon fontSize="inherit" color="inherit">
          edit
        </Icon>
      </MDButton>
    ) : (
      <MDButton variant="gradient" color="light" size={"small"} onClick={handleOpen}>
        Register
      </MDButton>
    );

  return (
    <div>
      <MDContentModal
        isOpen={open}
        setClose={() => setOpen(false)}
        button={<Button handleOpen={() => setOpen(true)} />}
      >
        <Fade in={open}>
          <Card sx={style}>
            <MDBox variant="gradient" bgColor="dark" p={2} textAlign="center">
              <MDTypography variant="h5" fontWeight="regular" color="white">
                {isUpdate ? "Update" : "Register"} Device
              </MDTypography>
            </MDBox>
            {error && (
              <MDAlert color="error" dismissible>
                {error}
              </MDAlert>
            )}
            {message && (
              <MDAlert color="success" dismissible>
                {message}
              </MDAlert>
            )}
            <MDBox pt={2} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDInput
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    label="Name"
                    value={name}
                    required
                    error={!isEmpty(nameError)}
                    fullWidth
                    autoComplete="off"
                  />
                </MDBox>
                {!isEmpty(nameError) && (
                  <MDBox mt={-3} mb={1}>
                    <MDTypography variant="caption" fontWeight="regular" color="error">
                      {nameError}
                    </MDTypography>
                  </MDBox>
                )}
                <MDBox mb={2}>
                  <MDInput
                    onChange={(e) => setBrand(e.target.value)}
                    type="text"
                    label="Brand"
                    value={brand}
                    error={!isEmpty(brandError)}
                    fullWidth
                    autoComplete="off"
                  />
                </MDBox>
                {!isEmpty(brandError) && (
                  <MDBox mt={-3} mb={1}>
                    <MDTypography variant="caption" fontWeight="regular" color="error">
                      {brandError}
                    </MDTypography>
                  </MDBox>
                )}
                <MDBox mb={2}>
                  <MDInput
                    onChange={(e) => setModel(e.target.value)}
                    type="text"
                    label="Model"
                    value={model}
                    error={!isEmpty(modelError)}
                    fullWidth
                    autoComplete="off"
                  />
                </MDBox>
                {!isEmpty(modelError) && (
                  <MDBox mt={-3} mb={1}>
                    <MDTypography variant="caption" fontWeight="regular" color="error">
                      {modelError}
                    </MDTypography>
                  </MDBox>
                )}
                <Grid container spacing={1} mt={2} mb={1}>
                  <Grid item xs={12} md={6} xl={6}>
                    <MDButton
                      variant="gradient"
                      color="light"
                      fullWidth
                      onClick={handleClose}
                      disabled={submitting}
                    >
                      Cancel
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} md={6} xl={6} textAlign={"right"}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      fullWidth
                      onClick={submitForm}
                      disabled={submitting}
                    >
                      {submitting ? "Please wait..." : isUpdate ? "Update" : "Register"}
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Card>
        </Fade>
      </MDContentModal>
    </div>
  );
}

Form.propTypes = {
  institutionId: PropTypes.string.isRequired,
  data: PropTypes.object,
  index: PropTypes.number,
  addData: PropTypes.func,
  updateData: PropTypes.func,
};

export default Form;
