import { memo, useEffect, useState, useCallback } from "react";

import PropTypes from "prop-types";

import { APIProvider, Map, MapControl, ControlPosition } from "@vis.gl/react-google-maps";
import { ErrorBoundary } from "react-error-boundary";

import { Circle } from "components/MDMaps/components/Geometry";
import ControlPanel from "components/MDMaps/components/ControlPanel";
import PlacesHandler from "components/MDMaps/handlers/PlacesHandler";
import PlaceAutocomplete from "components/MDMaps/components/PlaceAutocomplete";
import ClusterMarkers from "components/MDMaps/components/Marker/ClusterMarkers";
import DefaultMarker from "components/MDMaps/components/Marker/DefaultMarker";

import MDBox from "components/MDBox";
import MDPageError from "components/MDError/MDPageError";
import PieSocket from "piesocket-js";
import { googleMapID, googleAPIKey, pieSocketClusterId, pieSocketAPIKey } from "config/app";
import mapPinRed from "assets/images/map-pin-red.png";
import mapPinBlue from "assets/images/map-pin-blue.png";
import mapMarker1 from "assets/images/map-marker-1.png";

import { webSocketAuthUrl } from "config/app";

function Maps({
  defaultLocation,
  trackingLocation,
  updateDefaultLocation,
  updateTrackingLocation,
  locations,
  isClustered,
  isSearchable,
  isGeofenced,
  isDraggable,
  isTracking,
  showInfoWindow,
  allowLocationChange,
  disableDefaultUI,
}) {
  const [defaultCenter, setDefaultCenter] = useState(
    defaultLocation.position ? defaultLocation.position : { lat: 0, lng: 0 }
  );
  const [center, setCenter] = useState(defaultCenter);
  const [trackingCenter, setTrackingCenter] = useState(
    trackingLocation ? trackingLocation.position : { lat: 0, lng: 0 }
  );
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [radius, setRadius] = useState(Number(defaultLocation.radius));
  const [address, setAddress] = useState(defaultLocation.address ? defaultLocation.address : "");
  const [isTrackingLoading, setIsTrackingLoading] = useState(false);

  useEffect(() => {
    if (isTracking) {
      setDefaultCenter(trackingLocation.position);
    } else {
      if (defaultLocation.position != null) {
        setDefaultCenter(defaultLocation.position);
      } else if (allowLocationChange) {
        getCurrentPosition();
      }
    }
  }, []);

  useEffect(() => {
    if (!isTracking) {
      setCenter(defaultCenter);
    }
  }, [defaultCenter]);

  useEffect(() => {
    if (selectedPlace) {
      let lat = selectedPlace.geometry.location.lat();
      let lng = selectedPlace.geometry.location.lng();
      setCenter({ lat, lng });
    }
  }, [selectedPlace]);

  const changeCenter = useCallback(
    () => (newCenter) => {
      if (!newCenter) return;
      setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
    },
    [defaultCenter]
  );

  const getCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setDefaultCenter({ lat: latitude, lng: longitude });
        },
        (_) => {}
      );
    }
  };

  const getLiveLocation = (trackingId) => {
    setIsTrackingLoading(true);
    var pieSocket = new PieSocket({
      clusterId: pieSocketClusterId,
      apiKey: pieSocketAPIKey,
      authEndpoint: webSocketAuthUrl,
      notifySelf: true,
    });
    const subscriptionId = `tracking-${trackingId}`;

    pieSocket.subscribe(subscriptionId).then((channel) => {
      channel.listen("current-location", (data, meta) => {
        setIsTrackingLoading(false);
        if (data) {
          let position = JSON.parse(data);
          setTrackingCenter({ lat: position.latitude, lng: position.longitude });
          updateTrackingLocation({ lat: position.latitude, lng: position.longitude });
          setTimeout(() => setTrackingCenter(), 5000);
        }
        pieSocket.unsubscribe(subscriptionId);
      });
      channel.publish("location-request", {});
      channel.on("message", (_) => {});
      channel.on("error", (_) => {
        setIsTrackingLoading(false);
        pieSocket.unsubscribe(subscriptionId);
      });
      channel.on("close", (_) => {});
    });
  };

  return (
    <MDBox sx={{ height: 400 }}>
      <ErrorBoundary FallbackComponent={MDPageError}>
        <APIProvider apiKey={googleAPIKey}>
          <Map
            mapId={googleMapID}
            center={trackingCenter && trackingCenter}
            defaultCenter={defaultCenter}
            defaultZoom={12}
            gestureHandling={defaultCenter != null && defaultCenter.lat != 0 ? "greedy" : "none"}
            disableDefaultUI={disableDefaultUI}
          >
            <MapControl position={ControlPosition.TOP_LEFT}>
              {isSearchable && <PlaceAutocomplete onPlaceSelect={setSelectedPlace} />}
            </MapControl>
            {defaultLocation && (
              <DefaultMarker
                title={defaultLocation.title}
                content={defaultLocation.content}
                position={center}
                draggable={isDraggable}
                showInfoWindow={showInfoWindow}
                onDrag={(e) => setCenter({ lat: e.latLng?.lat() ?? 0, lng: e.latLng?.lng() ?? 0 })}
              >
                <MDBox component="img" src={mapMarker1} width={50} height={50} />
              </DefaultMarker>
            )}
            {isTracking && (
              <DefaultMarker
                title={trackingLocation.title}
                content={trackingLocation.content}
                position={trackingLocation.position}
                draggable={false}
                showInfoWindow={showInfoWindow}
              >
                <MDBox component="img" src={mapPinRed} width={40} height={40} />
              </DefaultMarker>
            )}
            {isClustered ? (
              <ClusterMarkers
                isTracking={isTracking}
                locations={locations}
                showInfoWindow={showInfoWindow}
              />
            ) : (
              locations &&
              locations.map((location) => (
                <DefaultMarker
                  key={location.id}
                  title={location.title}
                  content={`${location.content}`}
                  position={location.position}
                  showInfoWindow={showInfoWindow}
                >
                  <MDBox
                    component="img"
                    src={isTracking ? mapPinBlue : mapPinRed}
                    width={30}
                    height={30}
                  />
                </DefaultMarker>
              ))
            )}
            {/* <Polygon strokeWeight={1.5} encodedPaths={POLYGONS} /> */}
            {/* <Polyline strokeWeight={10} strokeColor={"#ff22cc88"} encodedPath={POLYGONS[11]} /> */}
            {isGeofenced && (
              <Circle
                radius={radius}
                center={center}
                onRadiusChanged={setRadius}
                onCenterChanged={changeCenter}
                strokeColor={"#0c4cb3"}
                strokeOpacity={0.5}
                strokeWeight={1}
                fillColor={"#3b82f6"}
                fillOpacity={0.3}
                editable={false}
                draggable={false}
              />
            )}
            {(allowLocationChange || isTracking) && (
              <ControlPanel
                defaultId={defaultLocation && defaultLocation.id}
                trackingId={trackingLocation && trackingLocation.id}
                center={center}
                radius={radius}
                address={address}
                isTracking={isTracking}
                isTrackingLoading={isTrackingLoading}
                onCenterChanged={setCenter}
                onRadiusChanged={setRadius}
                onAddressChanged={setAddress}
                onTracking={getLiveLocation}
                onComplete={updateDefaultLocation}
              />
            )}
          </Map>
          {isSearchable && <PlacesHandler place={selectedPlace} />}
        </APIProvider>
      </ErrorBoundary>
    </MDBox>
  );
}

Maps.defaultProps = {
  isClustered: false,
  isSearchable: false,
  isGeofenced: false,
  isDraggable: false,
  isTracking: false,
  showInfoWindow: false,
  allowLocationChange: false,
  disableDefaultUI: false,
};

Maps.propTypes = {
  defaultLocation: PropTypes.object,
  trackingLocation: PropTypes.object,
  updateDefaultLocation: PropTypes.func,
  updateTrackingLocation: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.object),
  isClustered: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isGeofenced: PropTypes.bool,
  isDraggable: PropTypes.bool,
  isTracking: PropTypes.bool,
  showInfoWindow: PropTypes.bool,
  allowLocationChange: PropTypes.bool,
  disableDefaultUI: PropTypes.bool,
};

export default memo(Maps);
