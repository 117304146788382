/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import { usePermission } from "react-permission-role";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import Form from "layouts/institution/components/Modals/AccountsForm";

import userImage from "assets/images/user.png";

function AccountsCard({ id, title, list, updateSource, shadow }) {
  const { isAuthorized } = usePermission();
  const [isPermitted, setIsPermitted] = useState(false);

  useEffect(() => {
    const checkPermission = async () => {
      if (await isAuthorized(["SUPER ADMIN", "ADMIN"], "")) {
        setIsPermitted(true);
      }
    };
    checkPermission();
  }, []);

  const renderAccounts =
    list &&
    list.map(({ id, first_name, last_name, passcode }) => (
      <MDBox key={id} component="li" display="flex" alignItems="center" py={1} mb={1}>
        <MDBox mr={2}>
          <MDAvatar src={userImage} alt="" shadow="md" />
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <MDTypography variant="button" fontWeight="medium">
            {`${first_name} ${last_name}`}
          </MDTypography>
          <MDTypography variant="caption" color="text">
            Passcode:&nbsp;{`${passcode}`}
          </MDTypography>
        </MDBox>
      </MDBox>
    ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        {isPermitted && <Form id={id} onComplete={updateSource} defaultAccounts={list} />}
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderAccounts}
        </MDBox>
      </MDBox>
    </Card>
  );
}

AccountsCard.defaultProps = {
  shadow: true,
};

AccountsCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object),
  shadow: PropTypes.bool,
  updateSource: PropTypes.func.isRequired,
};

export default AccountsCard;
