import PropTypes from "prop-types";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function MDPageError({ url }) {
  const navigate = useNavigate();

  const goToUrl = () => {
    if (url) navigate(url);
    else window.location.reload();
  };

  return (
    <MDBox px={0} width="100%" height="60vh" mx="auto">
      <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
        <Grid item xs={11} sm={9} md={6} lg={6} xl={6}>
          <MDBox textAlign="center" sx={{ position: "relative" }}>
            <MDBox>
              <MDTypography variant="h3" fontWeight="bold" color="dark">
                An error occurred
              </MDTypography>
              <MDTypography variant="h5" fontWeight="bold" color="dark">
                Please click the button below to refresh the page
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="dark" onClick={goToUrl}>
                Try Again
              </MDButton>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

MDPageError.propTypes = {
  url: PropTypes.string,
};

export default MDPageError;
