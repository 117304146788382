import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDConfirmModal from "components/MDModal/MDConfirmModal";

import DeviceService from "services/DeviceService";

import { useAuth } from "context/AuthContext";

function Options({ index, institutionId, deviceId, action, onComplete }) {
  const { useSessionExpired } = useAuth();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);

  const title = (action === "delete" ? "Delete" : "Disable") + " Device";
  const text = "Are you sure you want to continue?";

  const confirmAction = () => {
    setError();
    setSubmitting(true);
    let response;
    if (action === "delete") {
      DeviceService.deleteDevice(institutionId, deviceId, onSuccess, onError);
    } else {
      DeviceService.disableDevice(institutionId, deviceId, onSuccess, onError);
    }
  };

  const onSuccess = (response) => {
    setSubmitting(false);
    if (response.data.success) {
      onComplete(index);
      setOpen(false);
    } else {
      setError(response.data.message);
    }
  };

  const onError = (error) => {
    setSubmitting(false);
    if (error?.response?.status == 401) {
      useSessionExpired();
    } else if (error?.response?.status >= 400 && error?.response?.status < 500) {
      setError(error?.response?.data?.message);
    } else {
      setError("An error occured");
    }
  };

  const Button = ({ action }) => (
    <MDButton
      variant="gradient"
      color={action === "delete" || action === "disable" ? "error" : "secondary"}
      size={"small"}
      onClick={() => setOpen(true)}
    >
      <Icon fontSize="inherit" color="inherit">
        {action === "delete" ? "delete" : "phonelink_erase"}
      </Icon>
    </MDButton>
  );

  return (
    <div>
      <MDConfirmModal
        title={title}
        text={text}
        error={error}
        isOpen={open}
        submitting={submitting}
        onConfirm={confirmAction}
        onComplete={onComplete}
        setClose={() => setOpen(false)}
        button={<Button action={action} handleOpen={() => setOpen(true)} />}
      />
    </div>
  );
}

Options.propTypes = {
  index: PropTypes.number.isRequired,
  deviceId: PropTypes.string.isRequired,
  institutionId: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
};

export default Options;
