import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-routers components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DataTable from "examples/Tables/DataTable";

// Data
import appsTableData from "layouts/institution/tabs/devices/device/data/appsTableData";

import DeviceService from "services/DeviceService";
import { useAuth } from "context/AuthContext";
import { setPageError, useMaterialUIController } from "context/UIContext";
import { isEmpty } from "lodash";

function Device({ institutionId, deviceNumber }) {
  const { useSessionExpired } = useAuth();
  const [controller, dispatch] = useMaterialUIController();

  const [device, setDevice] = useState({});
  const [apps, setApps] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchDeviceInfo();
    fetchApps();
  }, []);

  const fetchDeviceInfo = () => {
    DeviceService.fetchDeviceInfo(
      institutionId,
      deviceNumber,
      (response) => {
        if (!isEmpty(apps)) setIsLoading(false);
        if (response.data.success) {
          setDevice(response.data.result);
        } else setPageError(dispatch, response.data.message);
      },
      onFetchError
    );
  };

  const fetchApps = () => {
    DeviceService.fetchDeviceApps(
      institutionId,
      deviceNumber,
      (response) => {
        if (!isEmpty(device)) setIsLoading(false);
        if (response.data.success) {
          const apps = response.data.result.apps;
          const totalUsage = response.data.result.total_usage;
          const { columns, rows } = appsTableData(apps, totalUsage);
          setApps(apps);
          setRows(rows);
          setColumns(columns);
        } else setPageError(dispatch, response.data.message);
      },
      onFetchError
    );
  };

  const onFetchError = (error) => {
    if (error?.response?.status == 401) useSessionExpired();
    else setPageError(dispatch, "An error occurred, please try again later");
  };

  return isLoading ? (
    <MDBox display="flex" justifyContent="center" alignItems="center" mt={10}>
      <CircularProgress color="inherit" size={20} />
    </MDBox>
  ) : (
    !isEmpty(device) && (
      <MDBox mt={2}>
        <MDBox mb={2}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={8} lg={8}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox display="flex">
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                      Name:&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;{device.name}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox display="flex">
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                      Brand:&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;
                      {`${device.brand !== null ? device.brand : ""} 
                      ${device.model !== null ? device.model : ""}`}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox display="flex" mt={-2}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                      Number:&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;{device.number}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox display="flex" mt={-2}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                      Enrolled By:&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;{device.agent ? device.agent : "N/A"}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox display="flex" mt={-2}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                      Activation Date:&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;{device.activated_at ? device.activated_at : "N/A"}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox display="flex" mt={-2}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                      Expiration Date:&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;{device.expired_at ? device.expired_at : "N/A"}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox display="flex" mt={-2}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                      Unlock Code:&nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;{device.passcode ? device.passcode : "N/A"}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox display="flex" mt={2}>
                <MDButton
                  variant="gradient"
                  color={
                    device?.status?.toLowerCase() === "active"
                      ? "success"
                      : device?.status?.toLowerCase() === "pending"
                      ? "warning"
                      : "secondary"
                  }
                  size={"small"}
                  sx={{ cursor: "text" }}
                >
                  &nbsp;{device.status}
                </MDButton>
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              lg={2}
              sx={{
                ml: "auto",
                textAlign: "right",
                marginBottom: "auto",
              }}
            >
              <MDBox>
                <MDButton
                  variant="gradient"
                  color="dark"
                  size={"small"}
                  component={Link}
                  to={`${`/institutions/${institutionId}/maps/${device.number}`}`}
                >
                  View In Map
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {!isEmpty(apps) && apps.length > 0 && (
          <Card>
            <MDBox pb={1}>
              <DataTable
                table={{ columns, rows }}
                isSorted={true}
                entriesPerPage={{
                  defaultValue: 40,
                  entries: [10, 20, 30, 40],
                }}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        )}
      </MDBox>
    )
  );
}

Device.propTypes = {
  deviceNumber: PropTypes.string,
  institutionId: PropTypes.string,
};

export default Device;
