import React, { useEffect, useState, useCallback } from "react";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";

import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// This is a custom built autocomplete component using the "Autocomplete Service" for predictions
// and the "Places Service" for place details
const PlaceAutocomplete = ({ onPlaceSelect }) => {
  const map = useMap();
  const places = useMapsLibrary("places");

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] = useState();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] = useState(null);

  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] = useState(null);

  const [predictionResults, setPredictionResults] = useState([]);

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [map, places]);

  const fetchPredictions = useCallback(
    async (inputValue) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);
        return;
      }

      const request = { input: inputValue, sessionToken };
      const response = await autocompleteService.getPlacePredictions(request);

      setPredictionResults(response.predictions);
    },
    [autocompleteService, sessionToken]
  );

  const onInputChange = useCallback(
    (event) => {
      const value = event.target?.value;

      setInputValue(value);
      fetchPredictions(value);
    },
    [fetchPredictions]
  );

  const handleSuggestionClick = useCallback(
    (placeId) => {
      if (!places) return;

      const detailRequestOptions = {
        placeId,
        fields: ["geometry", "name", "formatted_address"],
        sessionToken,
      };

      const detailsRequestCallback = (placeDetails) => {
        onPlaceSelect(placeDetails);
        setPredictionResults([]);
        setInputValue(placeDetails?.formatted_address ?? "");
        setSessionToken(new places.AutocompleteSessionToken());
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
    },
    [onPlaceSelect, places, placesService, sessionToken]
  );

  return (
    <MDBox
      display="flex"
      justifyContent="space-between"
      ml={1}
      mt={1}
      sx={{ bgcolor: "background.paper" }}
    >
      <MDInput
        type="text"
        value={inputValue}
        min={0}
        fullWidth
        autoComplete="off"
        placeholder="Search a place..."
        onInput={(event) => onInputChange(event)}
      />
      {predictionResults.length > 0 && (
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none", position: "absolute", top: 45, bgcolor: "background.paper" }}
        >
          {predictionResults.map(({ place_id, description }) => {
            return (
              <MDBox
                key={place_id}
                component="li"
                py={1}
                pr={2}
                mb={1}
                sx={{ cursor: "pointer" }}
                onClick={() => handleSuggestionClick(place_id)}
              >
                <MDBox display="flex" alignItems="center" pt={0} px={1}>
                  <MDBox mr={1}>
                    <Icon sx={{ fontWeight: "bold" }}>place</Icon>
                  </MDBox>
                  <MDBox display="flex" flexDirection="column">
                    <MDTypography
                      color="text"
                      fontSize={12}
                      fontWeight="regular"
                      textOverflow="ellipsis"
                      noWrap
                      sx={{ maxWidth: 500 }}
                    >
                      {description}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <Divider sx={{ marginTop: 1, marginBottom: -2 }} />
              </MDBox>
            );
          })}
        </MDBox>
      )}
    </MDBox>
  );
};

PlaceAutocomplete.propTypes = {
  onPlaceSelect: PropTypes.func,
};

export default PlaceAutocomplete;
