import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import MDContentModal from "components/MDModal/MDContentModal";

import Util from "helper/Util";
import { useAuth } from "context/AuthContext";
import InstitutionService from "services/InstitutionService";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context/UIContext";
import { isEmpty } from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const inputsMap = {
  name: "name",
  devices_no: "devices_no",
};

function Form({ data, onComplete }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { useSessionExpired } = useAuth();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(data ? data.name : "");
  const [nameError, setNameError] = useState();
  const [devicesNo, setDevicesNo] = useState(
    data ? Number(data.allocated_devices.split(",").join("")) : 0
  );
  const [devicesNoError, setDevicesNoError] = useState();
  const [message, setMessage] = useState();
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [isUpdate] = useState(data !== undefined);

  const submitForm = () => {
    resetErrors();
    let hasError = false;
    if (isEmpty(name)) {
      hasError = true;
      setNameError("Name is required");
    }

    if (isNaN(devicesNo) || devicesNo < 0) {
      hasError = true;
      setDevicesNoError("Invalid device allocation value");
    }
    if (!hasError) {
      setSubmitting(true);
      const payload = { name, devices_no: devicesNo };
      if (isUpdate) InstitutionService.updateInfo(data.id, payload, onSuccess, onError);
      else InstitutionService.registerInsitution(payload, onSuccess, onError);
    }
  };

  const onSuccess = (response) => {
    setSubmitting(false);
    if (response.data.success) {
      setMessage(response.data.message);
      const institution = response.data.result;
      if (isUpdate) {
        onComplete(institution);
        handleClose();
      } else {
        navigate(`/institutions/${institution.id}/manager`);
      }
    } else setError(response.data.message);
  };

  const onError = (error) => {
    setSubmitting(false);
    if (error?.response?.status == 401) {
      useSessionExpired();
    } else if (error?.response?.status >= 400 && error?.response?.status < 500) {
      setError(error?.response?.data?.message);
      if (error?.response?.status === 400) {
        const errors = error?.response?.data?.errors;
        if (errors) {
          if (inputsMap.name in errors) setNameError(errors[inputsMap.name]);
          if (inputsMap.devices_no in errors) setDevicesNoError(errors[inputsMap.devices_no]);
        }
      }
    } else setError("An error occured, please try again later");
  };

  const resetErrors = () => {
    setError();
    setMessage();
    setNameError();
    setDevicesNoError();
  };

  const handleOpen = () => setOpen(true);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    if (reason === "escapeKeyDown") {
      return false;
    }
    resetErrors();
    setOpen(false);
  };

  const Button = () =>
    isUpdate ? (
      <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={handleOpen}>
        <Tooltip title={"Edit Basic Info"} placement="top">
          <Icon>edit</Icon>
        </Tooltip>
      </MDButton>
    ) : (
      <MDButton variant="gradient" color="light" size={"small"} onClick={handleOpen}>
        Register
      </MDButton>
    );

  return (
    <div>
      <MDContentModal
        isOpen={open}
        setClose={() => setOpen(false)}
        button={<Button handleOpen={() => setOpen(true)} />}
      >
        <Fade in={open}>
          <Card sx={style}>
            <MDBox variant="gradient" bgColor="dark" p={2} textAlign="center">
              <MDTypography variant="h5" fontWeight="regular" color="white">
                {isUpdate ? "Update Basic Info" : "Register Insitution"}
              </MDTypography>
            </MDBox>
            {error && (
              <MDAlert color="error" dismissible>
                {error}
              </MDAlert>
            )}
            {message && (
              <MDAlert color="success" dismissible>
                {message}
              </MDAlert>
            )}
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox>
                  <MDInput
                    type="text"
                    label="Name"
                    value={name}
                    required
                    error={!isEmpty(nameError)}
                    fullWidth
                    autoComplete="off"
                    onChange={(e) => setName(e.target.value)}
                  />
                </MDBox>
                {!isEmpty(nameError) && (
                  <MDBox mt={-1} mb={-1}>
                    <MDTypography variant="caption" fontWeight="regular" color="error">
                      {nameError}
                    </MDTypography>
                  </MDBox>
                )}
                <MDBox mt={2}>
                  <MDInput
                    type="number"
                    min={0}
                    label="Devices Slots"
                    value={devicesNo}
                    error={!isEmpty(devicesNoError)}
                    fullWidth
                    autoComplete="off"
                    onChange={(e) => setDevicesNo(e.target.value)}
                  />
                </MDBox>
                {!isEmpty(devicesNoError) && (
                  <MDBox mt={-1} mb={-1}>
                    <MDTypography variant="caption" fontWeight="regular" color="error">
                      {devicesNoError}
                    </MDTypography>
                  </MDBox>
                )}
                <Grid container spacing={1} mt={3} mb={1}>
                  <Grid item xs={12} md={6} xl={6}>
                    <MDButton
                      variant="gradient"
                      color="light"
                      fullWidth
                      onClick={handleClose}
                      disabled={submitting}
                    >
                      Cancel
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} md={6} xl={6} textAlign={"right"}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      fullWidth
                      onClick={submitForm}
                      disabled={submitting}
                    >
                      {submitting ? "Please wait..." : isUpdate ? "Update" : "Register"}
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Card>
        </Fade>
      </MDContentModal>
    </div>
  );
}

Form.propTypes = {
  data: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Form;
