import { Fragment, useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import MDContentModal from "components/MDModal/MDContentModal";

import { useAuth } from "context/AuthContext";
import AccountService from "services/AccountService";
import InstitutionService from "services/InstitutionService";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context/UIContext";
import { isEmpty } from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const inputsMap = {
  accounts: "accounts",
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FETCH_LIMIT = 1000;

function Form({ id, defaultAccounts, onComplete }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { useSessionExpired } = useAuth();
  const [open, setOpen] = useState(false);
  const [initialValue, setInitialValue] = useState(defaultAccounts);
  const [accounts, setAccounts] = useState();
  const [accountsError, setAccountsError] = useState();
  const [message, setMessage] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (open) fetchUsers();
  }, [open]);

  useEffect(() => {
    if (defaultAccounts) onAccountSelected(defaultAccounts);
  }, [defaultAccounts]);

  const onAccountSelected = (values) => {
    let accounts = [];
    values.map((v) => accounts.push(v.id));
    setAccounts(accounts);
  };

  const fetchUsers = () => {
    setLoading(true);
    AccountService.fetchUsers(
      true,
      0,
      FETCH_LIMIT,
      (response) => {
        setLoading(false);
        if (response.data.success) {
          const users = response.data.result.users;
          setUsers(users);
        } else setError(response.data.message);
      },
      onError
    );
  };

  const submitForm = () => {
    setError();
    setMessage();
    setAccountsError();
    setSubmitting(true);
    const payload = { accounts };
    InstitutionService.updateAccounts(
      id,
      payload,
      (response) => {
        setSubmitting(false);
        if (response.data.success) {
          setMessage(response.data.message);
          onComplete(response.data.result);
          setInitialValue(response.data.result.accounts);
          handleClose();
        } else setError(response.data.message);
      },
      onError
    );
  };

  const onError = (error) => {
    setLoading(false);
    if (error?.response?.status == 401) {
      useSessionExpired();
    } else if (error?.response?.status >= 400 && error?.response?.status < 500) {
      setError(error?.response?.data?.message);
      if (error?.response?.status === 400) {
        const errors = error?.response?.data?.errors;
        if (errors) {
          if (inputsMap.accounts in errors) setAccountsError(errors[inputsMap.accounts]);
        }
      }
    } else setError("An error occured, please try again later");
  };

  const handleOpen = () => setOpen(true);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    if (reason === "escapeKeyDown") {
      return false;
    }
    setMessage();
    setError();
    setOpen(false);
  };

  const Button = () => (
    <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={handleOpen}>
      <Tooltip title={"Add/Remove Accounts"} placement="top">
        <Icon>edit</Icon>
      </Tooltip>
    </MDButton>
  );

  return (
    <div>
      <MDContentModal
        isOpen={open}
        setClose={() => setOpen(false)}
        button={<Button handleOpen={() => setOpen(true)} />}
      >
        <Fade in={open}>
          <Card sx={style}>
            <MDBox variant="gradient" bgColor="dark" p={2} textAlign="center">
              <MDTypography variant="h5" fontWeight="regular" color="white">
                Add/Remove Accounts
              </MDTypography>
            </MDBox>
            {error && (
              <MDAlert color="error" dismissible>
                {error}
              </MDAlert>
            )}
            {message && (
              <MDAlert color="success" dismissible>
                {message}
              </MDAlert>
            )}
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <Autocomplete
                  multiple
                  id="autocomplete-tags"
                  options={users}
                  size="medium"
                  loading={loading}
                  disableCloseOnSelect
                  // inputValue=""
                  clearOnBlur={true}
                  key={initialValue}
                  defaultValue={initialValue}
                  onChange={(event, value) => onAccountSelected(value)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                  renderOption={(props, option, { selected }) => (
                    // eslint-disable-next-line
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option.first_name} ${option.last_name}`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      type="text"
                      label="Accounts"
                      placeholder="Search and select account(s)"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                    />
                  )}
                />
                {!isEmpty(accountsError) && (
                  <MDBox mt={-1} mb={-1}>
                    <MDTypography variant="caption" fontWeight="regular" color="error" mt={-1}>
                      {accountsError}
                    </MDTypography>
                  </MDBox>
                )}
                <Grid container spacing={1} mt={3} mb={1}>
                  <Grid item xs={12} md={6} xl={6}>
                    <MDButton
                      variant="gradient"
                      color="light"
                      fullWidth
                      onClick={handleClose}
                      disabled={submitting}
                    >
                      Cancel
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} md={6} xl={6} textAlign={"right"}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      fullWidth
                      onClick={submitForm}
                      disabled={submitting}
                    >
                      {submitting ? "Please wait..." : "Continue"}
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Card>
        </Fade>
      </MDContentModal>
    </div>
  );
}

Form.propTypes = {
  id: PropTypes.string,
  onComplete: PropTypes.func,
  defaultAccounts: PropTypes.arrayOf(PropTypes.object),
};

export default Form;
