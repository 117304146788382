import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import { ThemeProvider } from "@mui/material/styles";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context/UIContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
};

function MDConfirmModal({ button, title, text, error, isOpen, setClose, onConfirm, submitting }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    if (reason === "escapeKeyDown") {
      return false;
    }
    setOpen(false);
    setClose(true);
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      {button}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        disableEscapeKeyDown
      >
        <Fade in={open}>
          <Card sx={style}>
            <MDBox variant="gradient" bgColor="dark" p={1} textAlign="center">
              <MDTypography variant="h5" fontWeight="medium" color="white">
                {title}
              </MDTypography>
            </MDBox>
            <MDBox pt={1} pb={1} px={1}>
              {error && (
                <MDAlert color="error" dismissible>
                  {error}
                </MDAlert>
              )}
              <MDBox mb={0}>
                <MDTypography display="block" variant="h6" my={1}>
                  {text}
                </MDTypography>
              </MDBox>
              <Grid container spacing={1} mt={1} mb={1}>
                <Grid item xs={12} md={4} xl={4}></Grid>
                <Grid item xs={12} md={4} xl={4} textAlign={"right"}>
                  <MDButton
                    variant="gradient"
                    color="light"
                    fullWidth
                    onClick={handleClose}
                    disabled={submitting}
                  >
                    Cancel
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={4} xl={4} textAlign={"right"}>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    fullWidth
                    onClick={onConfirm}
                    disabled={submitting}
                  >
                    Continue
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
}

MDConfirmModal.defaulProps = {
  isOpen: false,
  submitting: false,
};

MDConfirmModal.propTypes = {
  button: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  setClose: PropTypes.func,
  error: PropTypes.string,
  submitting: PropTypes.bool,
};

export default MDConfirmModal;
