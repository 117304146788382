import { useState, useEffect } from "react";

// react-routers components
import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DataTable from "examples/Tables/DataTable";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import breakpoints from "assets/theme/base/breakpoints";

import Form from "layouts/institution/components/Modals/InfoForm";

import InstitutionService from "services/InstitutionService";

import listTableData from "layouts/institutions/data/listTableData";

import { useAuth } from "context/AuthContext";
import { setPageError, useMaterialUIController } from "context/UIContext";

const tabsMap = {
  OPEN: 0,
  CLOSED: 1,
};

function Insitutions() {
  const { useSessionExpired } = useAuth();
  const [controller, dispatch] = useMaterialUIController();

  const { status } = useParams();
  const navigate = useNavigate();
  const match = /(open|closed)/.test(status);
  const statusValue = status && match ? status.toUpperCase() : "OPEN";

  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [dataLimit, setDataLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabIndex, setTabIndex] = useState(
    Object.keys(tabsMap).includes(statusValue) ? tabsMap[statusValue] : 0
  );

  useEffect(() => {
    if (!statusValue) navigate(`/institutions`);
    setTabIndex(Object.keys(tabsMap).includes(statusValue) ? tabsMap[statusValue] : 0);
  }, [statusValue]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    setCurrentPage(0);
    if (isSearching) {
      searchInstitutions(searchKeyword, 0, dataLimit);
    } else fetchInstitutions(0, dataLimit);
  }, [statusValue]);

  useEffect(() => {
    if (isLoaded) {
      if (isSearching) {
        searchInstitutions(searchKeyword, currentPage, dataLimit);
      } else fetchInstitutions(currentPage, dataLimit);
    }
  }, [currentPage, isSearching]);

  useEffect(() => {
    if (isLoaded) {
      if (isSearching) {
        searchInstitutions(searchKeyword, 0, dataLimit);
      } else fetchInstitutions(0, dataLimit);
    }
  }, [dataLimit]);

  useEffect(() => {
    if (isLoaded) {
      const { rows, columns } = listTableData(data, deleteData);
      setColumns(columns);
      setRows(rows);
    }
  }, [data]);

  useEffect(() => {
    if (isLoaded) {
      if (searchKeyword && searchKeyword.trim().length >= 3) {
        setCurrentPage(0);
        setIsSearching(true);
      } else if (searchKeyword === undefined) {
        setCurrentPage(0);
        setIsSearching(false);
      }
    }
  }, [searchKeyword]);

  const fetchInstitutions = (page, limit) => {
    setIsLoading(true);
    InstitutionService.fetchAll(statusValue, page, limit, onSuccess, onError);
  };

  const searchInstitutions = (keyword, page, limit) => {
    InstitutionService.searchAll(keyword, statusValue, page, limit, onSuccess, onError);
  };

  const onSuccess = (response) => {
    setIsLoaded(true);
    setIsLoading(false);
    if (response.data.success) {
      setData(response.data.result.institutions);
      setTotalCount(response.data.result.total);
    } else setPageError(dispatch, response.data.message);
  };

  const onError = (error) => {
    setIsLoading(false);
    if (error?.response?.status == 401) useSessionExpired();
    else setPageError(dispatch, "An error occurred, please try again later");
  };

  const addData = (item) => {
    if (tabIndex === 0) {
      const newData = [item, ...data];
      setData(newData);
    }
  };

  const deleteData = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const handleSetTabValue = (event, newValue) => {
    setTabIndex(tabsMap[newValue.toUpperCase()]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={0} mb={2} alignItems="center">
          <Grid item xs={6} md={3} lg={4}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={status && match ? status : "open"}
                onChange={handleSetTabValue}
              >
                <Tab
                  label="Open"
                  value="open"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      meeting_room
                    </Icon>
                  }
                  onClick={() => navigate(`/institutions/open`)}
                />
                <Tab
                  label="Closed"
                  value="closed"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      no_meeting_room
                    </Icon>
                  }
                  onClick={() => navigate(`/institutions/closed`)}
                />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item xs={6} md={3} lg={2} sx={{ ml: "auto", textAlign: "right" }}>
            {tabIndex == 0 && <Form addData={(d) => addData(d)} />}
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={{
                    defaultValue: dataLimit,
                    entries: [10, 20, 30, 40],
                  }}
                  pagination={{
                    variant: "gradient",
                    color: "secondary",
                  }}
                  showTotalEntries={true}
                  canSearch={true}
                  isServerSide={true}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalCount={totalCount}
                  searchPlaceholder={"Search Names..."}
                  setDataLimit={setDataLimit}
                  setSearchKeyword={setSearchKeyword}
                  isLoading={isLoading}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Insitutions;
