/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DeviceOptions from "layouts/settings/components/DeviceOptions";
import SettingService from "services/SettingService";
import { setPageError, useMaterialUIController } from "context/UIContext";
import { useAuth } from "context/AuthContext";

function Settings() {
  const [controller, dispatch] = useMaterialUIController();
  const { useSessionExpired } = useAuth();
  const [options, setOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    SettingService.fetchOptions(onFetchSuccess, onFetchError);
  }, []);

  const onFetchSuccess = (response) => {
    setIsLoading(false);
    if (response.data.success) {
      setOptions(response.data.result);
    } else setPageError(dispatch, response.data.message);
  };

  const onFetchError = (error) => {
    setIsLoading(false);
    if (error?.response?.status == 401) useSessionExpired();
    else setPageError(dispatch, "An error occurred, please try again later");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <MDBox display="flex" justifyContent="center" alignItems="center" sx={{ height: 200 }}>
          <CircularProgress color="inherit" size={20} />
        </MDBox>
      ) : (
        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              {options && <DeviceOptions options={options.device} setOptions={setOptions} />}
            </Grid>
          </Grid>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Settings;
