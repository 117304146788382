import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import { ThemeProvider } from "@mui/material/styles";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context/UIContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
};

function MDSessionModal() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const reload = () => {
    window.location.reload();
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        disableEscapeKeyDown
      >
        <Fade in={true}>
          <Card sx={style}>
            <MDBox variant="gradient" bgColor="dark" p={1} textAlign="center">
              <MDTypography variant="h5" fontWeight="medium" color="white">
                Session Expired
              </MDTypography>
            </MDBox>
            <MDBox pt={1} pb={1} px={1}>
              <MDBox mb={0}>
                <MDTypography display="block" variant="h6" my={1}>
                  Your session has expired, please reload to sign in again
                </MDTypography>
              </MDBox>
              <Grid container spacing={1} mt={1} mb={1}>
                <Grid item xs={12} md={8} xl={8}></Grid>
                <Grid item xs={12} md={4} xl={4} textAlign={"right"}>
                  <MDButton variant="gradient" color="dark" fullWidth onClick={reload}>
                    Reload
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
}

export default MDSessionModal;
