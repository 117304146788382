/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDProgress from "components/MDProgress";
import MDTypography from "components/MDTypography";

import Util from "helper/Util";

export default function data(list, totalUsage) {
  const App = ({ name, usage_time }) => {
    let progress = 0;
    if (totalUsage > 0 && usage_time > 0) {
      progress = (usage_time * 100) / totalUsage;
    }
    progress = Number(Util.formatNumber(progress, 0));
    let color = progress >= 50 ? "success" : progress >= 25 ? "info" : "error";
    return (
      <MDBox sx={{ width: 400 }}>
        <MDBox display="flex" alignItems="center">
          <MDBox display="flex" alignItems="center">
            <Icon fontSize="small" color="inherit">
              touch_app
            </Icon>
            <MDTypography
              display="block"
              variant="button"
              fontWeight="regular"
              ml={1}
              lineHeight={1}
            >
              {name}
            </MDTypography>
          </MDBox>
          <MDTypography variant="caption" color="text" fontWeight="regular" sx={{ ml: "auto" }}>
            {`${usage_time > 0 ? `${Util.formatMilliSeconds(usage_time)} (${progress}%)` : ``}`}
          </MDTypography>
        </MDBox>
        <MDBox mt={0.5}>
          {progress > 0 && <MDProgress variant="gradient" color={color} value={progress} />}
        </MDBox>
      </MDBox>
    );
  };

  return {
    columns: [
      { Header: "app", accessor: "app", align: "left", width: "50%" },
      { Header: "usage count", accessor: "usage_count", align: "center" },
      { Header: "data usage", accessor: "data_usage", align: "center" },
      { Header: "last event time", accessor: "last_event_time", align: "center" },
    ],

    rows: list.map((item) => {
      return {
        app: <App id={item.id} name={item.app_name} usage_time={item.usage_time} />,
        usage_count: (
          <MDTypography component="a" href="#" variant="button" color="dark" fontWeight="regular">
            {item.usage_count} times
          </MDTypography>
        ),
        data_usage: (
          <MDTypography component="a" href="#" variant="caption" color="dark" fontWeight="regular">
            {Util.formatBytes(item.network_time)}
          </MDTypography>
        ),
        last_event_time: (
          <MDTypography component="a" href="#" variant="caption" color="dark" fontWeight="regular">
            {!!item.last_event_time ? item.last_event_time : ""}
          </MDTypography>
        ),
      };
    }),
  };
}
