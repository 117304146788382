/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";

// @mui material components
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

import Options from "layouts/institutions/components/Modals/Options";

export default function data(list, closeInstitution) {
  const Name = ({ id, name, enrollmentCode, status }) => (
    <MDBox
      display="flex"
      alignItems="center"
      lineHeight={1}
      component={status.toLowerCase() === `open` ? Link : ``}
      to={`${status.toLowerCase() === `open` ? `/institutions/${id}/monitor` : ``}`}
    >
      <Icon fontSize="medium" color="inherit">
        meeting_room
      </Icon>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption" fontWeight="regular">
          Enrollment Code:&nbsp;{enrollmentCode}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Devices = ({ activatedNo, allocatedNo }) => {
    let progress;
    if (activatedNo > 0) {
      progress = (activatedNo * 100) / allocatedNo;
    } else {
      progress = 0;
    }
    progress = Math.trunc(progress);
    return (
      <MDBox>
        <MDTypography display="block" variant="caption" color="text" fontWeight="regular">
          {activatedNo}&nbsp;of&nbsp;{allocatedNo}
        </MDTypography>
        <MDBox width="9rem">
          <MDProgress variant="gradient" color={"info"} value={progress} />
        </MDBox>
        <MDTypography display="block" variant="caption" color="text" fontWeight="regular">
          {progress}%
        </MDTypography>
      </MDBox>
    );
  };

  return {
    columns: [
      { Header: "name", accessor: "name", width: "25%", align: "left" },
      { Header: "address", accessor: "address", width: "25%", align: "center" },
      { Header: "devices", accessor: "devices", align: "center" },
      { Header: "action", accessor: "action", align: "center", disableSortBy: true },
    ],

    rows: list.map((item, i) => {
      return {
        name: (
          <Name
            id={item.id}
            name={item.name}
            enrollmentCode={item.enrollment_code}
            status={item.status}
          />
        ),
        address: (
          <MDBox>
            <MDTypography variant="caption" color="text" fontWeight="regular">
              {item.address ? item.address : ""}
            </MDTypography>
          </MDBox>
        ),
        devices: (
          <Devices activatedNo={item.activated_devices} allocatedNo={item.allocated_devices} />
        ),
        action: (
          <MDBox>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={6}>
                <Options
                  id={item.id}
                  index={i}
                  onComplete={(i) => closeInstitution(i)}
                  action={item.status === "OPEN" ? "close" : "open"}
                />
              </Grid>
            </Grid>
          </MDBox>
        ),
      };
    }),
  };
}
