import { useState, useEffect } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

import { usePermission } from "react-permission-role";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import CoverLayout from "layouts/auth/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { useAuth } from "context/AuthContext";

import AuthService from "services/AuthService";

import { brandName } from "config/app";
import { isEmpty } from "lodash";

function Basic() {
  const { setUser } = usePermission();
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState();
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);

  const { isAuthenticated, useLogin } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) navigate("/dashboard");
  }, [isAuthenticated]);

  const doLogin = () => {
    resetErrors();
    let hasError = false;
    if (isEmpty(email)) {
      hasError = true;
      setEmailError(true);
    }

    if (isEmpty(password)) {
      hasError = true;
      setPasswordError(true);
    }

    if (!hasError) {
      setSubmitting(true);
      const payload = { email, password };
      AuthService.login(payload, onSuccess, onError);
    }
  };

  const onSuccess = (response) => {
    setSubmitting(false);
    if (response.data.success) {
      const result = response.data.result;
      setUser(result.user);
      useLogin(result);
    } else {
      setError(response.data.message);
    }
  };

  const onError = (error) => {
    setSubmitting(false);
    if (error?.response?.status >= 400 && error?.response?.status < 500) {
      setError(error?.response?.data?.message);
    } else {
      setError("An error occured, please try again later");
    }
  };

  const resetErrors = () => {
    setError();
    setEmailError();
    setPasswordError();
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {brandName}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to continue
          </MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          {error && (
            <MDAlert color="error" dismissible>
              {error}
            </MDAlert>
          )}
          <MDBox component="form" role="form" pt={2}>
            <MDBox mb={2}>
              <MDInput
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                label="Email"
                error={emailError}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                label="Password"
                error={passwordError}
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="dark"
                fullWidth
                onClick={doLogin}
                disabled={submitting}
              >
                {submitting ? "Please wait..." : "Login"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Basic;
