/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ProfileService from "services/ProfileService";
import { useAuth } from "context/AuthContext";
import { isEmpty } from "lodash";

const inputsMap = {
  current_password: "current_password",
  new_password: "new_password",
  confirm_password: "confirm_password",
};

function Profile() {
  const { useSessionExpired } = useAuth();

  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState();
  const [message, setMessage] = useState();
  const [error, setError] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitForm = () => {
    resetErrors();
    let hasError = false;
    if (isEmpty(currentPassword)) {
      hasError = true;
      setCurrentPasswordError("Current password is required");
    }
    if (isEmpty(newPassword)) {
      hasError = true;
      setNewPasswordError("New password is required");
    }

    if (isEmpty(confirmPassword) || newPassword !== confirmPassword) {
      hasError = true;
      setConfirmPasswordError("Password confirmation mismatch");
    }

    if (!hasError) {
      setIsSubmitting(true);
      const payload = {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      };
      ProfileService.changePassword(
        payload,
        (response) => {
          setIsSubmitting(false);
          if (response.data.success) {
            setMessage(response.data.message);
          } else {
            setError(response.data.message);
          }
          resetForm();
        },
        (error) => {
          setIsSubmitting(false);
          if (error?.response?.status == 401) {
            useSessionExpired();
          } else if (error?.response?.status >= 400 && error?.response?.status < 500) {
            setError(error?.response?.data?.message);
            const errors = error?.response?.data?.errors;
            if (errors) {
              if (inputsMap.current_password in errors)
                setCurrentPasswordError(errors[inputsMap.current_password]);
              if (inputsMap.new_password in errors)
                setNewPasswordError(errors[inputsMap.new_password]);
              if (inputsMap.confirm_password in errors)
                setConfirmPasswordError(errors[inputsMap.confirm_password]);
            }
          } else {
            setError("An error occured, please try again later");
          }
        }
      );
    }
  };

  const resetForm = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const resetErrors = () => {
    setError();
    setMessage();
    setCurrentPasswordError();
    setNewPasswordError();
    setConfirmPasswordError();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={1}
                px={2}
              >
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Change Password
                </MDTypography>
              </MDBox>
              <MDBox p1={3} pb={2} px={2} component="form" role="form">
                <MDBox mb={1}>
                  {error && (
                    <MDAlert color="error" dismissible>
                      {error}
                    </MDAlert>
                  )}
                  {message && (
                    <MDAlert color="success" dismissible>
                      {message}
                    </MDAlert>
                  )}
                </MDBox>
                <Grid container spacing={1} mt={1} mb={2}>
                  <Grid item xs={12} md={12} xl={12}>
                    <MDBox>
                      <MDInput
                        type="password"
                        label="Current Password"
                        value={currentPassword}
                        required
                        fullWidth
                        autoComplete={"off"}
                        error={!isEmpty(currentPasswordError)}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </MDBox>
                    {!isEmpty(currentPasswordError) && (
                      <MDBox mt={-1} mb={-1}>
                        <MDTypography variant="caption" fontWeight="regular" color="error">
                          {currentPasswordError}
                        </MDTypography>
                      </MDBox>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <MDBox mt={2}>
                      <MDInput
                        type="password"
                        label="New Password"
                        value={newPassword}
                        required
                        fullWidth
                        autoComplete={"off"}
                        error={!isEmpty(newPasswordError)}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </MDBox>
                    {!isEmpty(newPasswordError) && (
                      <MDBox mt={-1} mb={-1}>
                        <MDTypography variant="caption" fontWeight="regular" color="error">
                          {newPasswordError}
                        </MDTypography>
                      </MDBox>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <MDBox mt={2}>
                      <MDInput
                        type="password"
                        label="Confirm Password"
                        value={confirmPassword}
                        fullWidth
                        required
                        autoComplete={"off"}
                        error={!isEmpty(confirmPasswordError)}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </MDBox>
                    {!isEmpty(confirmPasswordError) && (
                      <MDBox mt={-1} mb={-1}>
                        <MDTypography variant="caption" fontWeight="regular" color="error">
                          {confirmPasswordError}
                        </MDTypography>
                      </MDBox>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} xl={6} mt={2} textAlign={"right"}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      fullWidth
                      onClick={submitForm}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Please wait..." : "Continue"}
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Profile;
