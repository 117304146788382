import apiClient from "config/api";
const path = "/main/util";

class UtilService {
  fetchStats = (onSuccess, onError) => {
    return apiClient
      .get(`${path}/stats`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  fetchQRCode = (onSuccess, onError) => {
    return apiClient
      .get(`${path}/qrcode`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };
}

export default new UtilService();
