import apiClient from "config/api";
const path = "/main/institutions";

class InstitutionService {
  fetchAll = (status, page, limit, onSuccess, onError) => {
    return apiClient
      .get(`${path}?status=${status}&page=${page}&limit=${limit}`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  searchAll = (keyword, status, page, limit, onSuccess, onError) => {
    return apiClient
      .get(`${path}/search?keyword=${keyword}&status=${status}&page=${page}&limit=${limit}`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  fetchDetails = (id, onSuccess, onError) => {
    return apiClient
      .get(`${path}/${id}/details`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  registerInsitution = (payload, onSuccess, onError) => {
    return apiClient
      .post(`${path}/register`, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  updateInfo = (id, payload, onSuccess, onError) => {
    return apiClient
      .put(`${path}/${id}/info`, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  updateLocation = (id, payload, onSuccess, onError) => {
    return apiClient
      .put(`${path}/${id}/location`, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  updateAccounts = (id, payload, onSuccess, onError) => {
    return apiClient
      .put(`${path}/${id}/accounts`, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  changeStatus = (id, status, onSuccess, onError) => {
    return apiClient
      .patch(`${path}/${id}/status?status=${status}`, {})
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  deleteInstitution = (id, onSuccess, onError) => {
    return apiClient
      .delete(`${path}/${id}`, {})
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };
}

export default new InstitutionService();
