/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context/UIContext";

function User({ index, data, noGutter, isPermitted, setSelectedIndex }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      pl={2}
      // pb={0}
      // px={0}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <Grid container spacing={3} alignItems="center" mb={1}>
          <Grid item xs={12} md={9} lg={9}>
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {`${data.first_name} ${data.last_name}`}
            </MDTypography>
          </Grid>
          {isPermitted && (
            <Grid item xs={12} md={2} lg={2}>
              <MDButton
                variant="text"
                width={"auto"}
                sx={{ px: 0 }}
                color={darkMode ? "white" : "dark"}
                onClick={() => setSelectedIndex(index)}
              >
                <Icon>edit</Icon>&nbsp;edit
              </MDButton>
            </Grid>
          )}
        </Grid>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            <MDTypography variant="caption" fontWeight="medium">
              Email Address:&nbsp;&nbsp;
            </MDTypography>
            {data.email}
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              Roles:&nbsp;&nbsp;
            </MDTypography>
            {data.roles.join(", ")}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

User.defaultProps = {
  noGutter: false,
};

User.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  noGutter: PropTypes.bool,
  isPermitted: PropTypes.bool,
  setSelectedIndex: PropTypes.func,
};

export default User;
