/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { usePermission } from "react-permission-role";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import SettingService from "services/SettingService";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context/UIContext";
import { useAuth } from "context/AuthContext";
import { isEmpty } from "lodash";

const inputsMap = {
  tracking_interval: "device_live_tracking_interval",
  monitor_interval: "device_apps_monitor_interval",
};

function DeviceOptions({ options, setOptions }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { useSessionExpired } = useAuth();
  const { isAuthorized } = usePermission();
  const [isPermitted, setIsPermitted] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [enableGeofencing, setEnableGeofencing] = useState(options.enable_device_geo_fencing);
  const [enableLiveTracking, setEnableLiveTracking] = useState(options.enable_device_live_tracking);
  const [trackingInterval, setTrackingInterval] = useState(options.device_live_tracking_interval);
  const [trackingIntervalError, setTrackingIntervalError] = useState("");
  const [monitorInterval, setMonitorInterval] = useState(options.device_apps_monitor_interval);
  const [monitorIntervalError, setMonitorIntervalError] = useState("");
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const checkPermission = async () => {
      if (await isAuthorized(["SUPER ADMIN"], "")) {
        setIsPermitted(true);
      }
    };
    checkPermission();
  }, []);

  useEffect(() => {
    setTrackingInterval(enableLiveTracking ? trackingInterval : 0);
  }, [enableLiveTracking]);

  const submitForm = () => {
    resetErrors();
    let hasError = false;
    if (isNaN(trackingInterval) || trackingInterval < 0) {
      hasError = true;
      setTrackingIntervalError("Invalid device tracking interval value");
    }

    if (isNaN(monitorInterval) || monitorInterval < 0) {
      hasError = true;
      setMonitorIntervalError("Invalid device monitor interval value");
    }

    if (!hasError) {
      setIsSubmitting(true);
      const payload = {
        enable_device_geo_fencing: enableGeofencing,
        enable_device_live_tracking: enableLiveTracking,
        device_live_tracking_interval: trackingInterval,
        device_apps_monitor_interval: monitorInterval,
      };
      SettingService.updateDeviceOptions(payload, onSuccess, onError);
    }
  };

  const onSuccess = (response) => {
    setIsSubmitting(false);
    if (response.data.success) {
      setMessage(response.data.message);
      setOptions(response.data.result);
      setIsEditing(false);
    } else {
      setError(response.data.message);
    }
  };

  const onError = (error) => {
    setIsSubmitting(false);
    if (error?.response?.status == 401) {
      useSessionExpired();
    } else if (error?.response?.status >= 400 && error?.response?.status < 500) {
      setError(error?.response?.data?.message);
      if (error?.response?.status === 400) {
        const errors = error?.response?.data?.errors;
        if (errors) {
          if (inputsMap.tracking_interval in errors)
            setTrackingIntervalError(errors[inputsMap.tracking_interval]);
          if (inputsMap.monitor_interval in errors)
            setMonitorIntervalError(errors[inputsMap.monitor_interval]);
        }
      }
    } else setError("An error occured, please try again later");
  };

  const resetErrors = () => {
    setError();
    setMessage();
    setTrackingIntervalError();
    setMonitorIntervalError();
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Device Options
        </MDTypography>
        {isPermitted && (
          <MDBox display="flex" alignItems="flex-end">
            <MDButton
              variant="text"
              color={darkMode ? "white" : "dark"}
              onClick={() => setIsEditing((isEditing) => !isEditing)}
              sx={{ minHeight: 20, minWidth: 20, p: 1 }}
            >
              <Tooltip title={"Edit Device Options"} placement="top">
                <Icon>{`${isEditing ? "lock" : "edit"}`}</Icon>
              </Tooltip>
            </MDButton>
          </MDBox>
        )}
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        {error && (
          <MDAlert color="error" dismissible>
            {error}
          </MDAlert>
        )}
        {message && (
          <MDAlert color="success" dismissible>
            {message}
          </MDAlert>
        )}
        <MDBox component="form" role="form" px={1.5}>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox width="75%">
              <MDTypography variant="button" fontWeight="regular" color="text">
                Enable Geofencing
              </MDTypography>
            </MDBox>
            <MDBox mt={0.5} ml={0.5}>
              <Switch
                disabled={!isEditing}
                checked={enableGeofencing}
                onChange={() => setEnableGeofencing((enableGeofencing) => !enableGeofencing)}
              />
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={2} ml={-1.5}>
            <MDBox width="75%">
              <MDTypography variant="button" fontWeight="regular" color="text">
                Enable Live Tracking
              </MDTypography>
            </MDBox>
            <MDBox mt={0.5} ml={0.5}>
              <Switch
                disabled={!isEditing}
                checked={enableLiveTracking}
                onChange={() => setEnableLiveTracking((enableLiveTracking) => !enableLiveTracking)}
              />
            </MDBox>
          </MDBox>
          <MDBox mb={2} ml={-1.5}>
            <MDBox display="flex" alignItems="center">
              <MDBox mt={-0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Live Tracking Interval (Mins)
                </MDTypography>
              </MDBox>
              <MDBox ml={1} width={100}>
                <MDInput
                  type="number"
                  value={trackingInterval}
                  required
                  min={0}
                  disabled={!isEditing}
                  onChange={(e) => setTrackingInterval(Number(e.target.value))}
                  error={!isEmpty(trackingIntervalError)}
                />
              </MDBox>
            </MDBox>
            {!isEmpty(trackingIntervalError) && (
              <MDBox mb={-1}>
                <MDTypography variant="caption" fontWeight="regular" color="error">
                  {trackingIntervalError}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
          <MDBox mb={2} ml={-1.5}>
            <MDBox display="flex" alignItems="center">
              <MDBox mt={-0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Apps Monitor Interval (Mins)
                </MDTypography>
              </MDBox>
              <MDBox ml={1} width={100}>
                <MDInput
                  type="number"
                  value={monitorInterval}
                  required
                  min={0}
                  disabled={!isEditing}
                  onChange={(e) => setMonitorInterval(Number(e.target.value))}
                  error={!isEmpty(monitorIntervalError)}
                  autoComplete="off"
                />
              </MDBox>
            </MDBox>
            {!isEmpty(monitorIntervalError) && (
              <MDBox mb={-2}>
                <MDTypography variant="caption" fontWeight="regular" color="error">
                  {monitorIntervalError}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
          {isEditing && (
            <Grid container mt={4} mb={1}>
              <Grid item xs={12} md={4} xl={4} sx={{ ml: -1.5, mr: "auto" }}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  fullWidth
                  onClick={isPermitted && submitForm}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Please wait..." : "Save Changes"}
                </MDButton>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

DeviceOptions.propTypes = {
  options: PropTypes.object,
  setOptions: PropTypes.func,
};

export default DeviceOptions;
