// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function MDAuthError() {
  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox px={0} width="100%" height="80vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={6} lg={6} xl={6}>
            <MDBox textAlign="center" sx={{ position: "relative" }}>
              <MDBox>
                <MDTypography variant="h3" fontWeight="bold" color="dark">
                  Permission Denied
                </MDTypography>
                <MDTypography variant="h5" fontWeight="bold" color="dark">
                  You do not have the permissions to visit this page
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default MDAuthError;
