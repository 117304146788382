import { useState } from "react";
import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";

import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const DefaultMarker = ({
  position,
  children,
  title,
  content,
  draggable,
  onDrag,
  showInfoWindow,
}) => {
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        draggable={draggable}
        onDrag={onDrag}
        onClick={() => setInfoWindowOpen(true)}
        position={position}
        // title={title}
      >
        {children}
        {/* <Pin background={"#FF0"} glyphColor={"#F00"} borderColor={"#F00"} /> */}
      </AdvancedMarker>
      {showInfoWindow && infoWindowOpen && (
        <InfoWindow anchor={marker} maxWidth={200} onCloseClick={() => setInfoWindowOpen(false)}>
          <MDBox variant="gradient">
            <MDTypography variant="h6" noWrap sx={{ fontSize: 14 }}>
              {title}
            </MDTypography>
            <MDTypography variant="p" noWrap sx={{ fontSize: 12 }}>
              {content}
            </MDTypography>
          </MDBox>
        </InfoWindow>
      )}
    </>
  );
};

DefaultMarker.defaultProps = {
  draggable: false,
  showInfoWindow: false,
};

DefaultMarker.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  position: PropTypes.object,
  draggable: PropTypes.bool,
  showInfoWindow: PropTypes.bool,
  onDrag: PropTypes.func,
  children: PropTypes.node,
};

export default DefaultMarker;
