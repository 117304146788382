import apiClient from "config/api";
const path = "/admin/settings";

class SettingService {
  fetchOptions = (onSuccess, onError) => {
    return apiClient
      .get(`${path}/options`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  updateDeviceOptions = (payload, onSuccess, onError) => {
    return apiClient
      .put(`${path}/options/device`, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };
}

export default new SettingService();
