import axios from "axios";
import { baseUrl } from "./app";

const apiClient = axios.create({ baseURL: baseUrl });

function getAuthToken() {
  return localStorage.getItem("authorization");
}

apiClient.interceptors.request.use(
  (config) => {
    const authorization = getAuthToken();
    if (authorization) {
      config.headers["Authorization"] = `Bearer ${authorization}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiClient;
