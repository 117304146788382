import apiClient from "config/api";
import Util from "helper/Util";
let basePath = `/main/%s/devices`;

class DeviceService {
  fetchDeviceInfo = (institutionId, number, onSuccess, onError) => {
    let path = Util.formatString(basePath, institutionId);
    return apiClient
      .get(`${path}/${number}/info`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  fetchDevices = (institutionId, status, page, limit, onSuccess, onError) => {
    let path = Util.formatString(basePath, institutionId);
    return apiClient
      .get(`${path}?status=${status}&page=${page}&limit=${limit}`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  searchDevices = (institutionId, keyword, status, page, limit, onSuccess, onError) => {
    let path = Util.formatString(basePath, institutionId);
    return apiClient
      .get(`${path}/search?keyword=${keyword}&status=${status}&page=${page}&limit=${limit}`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  fetchLocations = (institutionId, offset, limit, onSuccess, onError) => {
    let path = Util.formatString(basePath, institutionId);
    return apiClient
      .get(`${path}/locations?offset=${offset}&limit=${limit}`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  fetchDeviceLocations = (institutionId, deviceNumber, offset, limit, onSuccess, onError) => {
    let path = Util.formatString(basePath, institutionId);
    return apiClient
      .get(`${path}/${deviceNumber}/locations?offset=${offset}&limit=${limit}`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  fetchDeviceMonitor = (institutionId, deviceNumber, onSuccess, onError) => {
    let path = Util.formatString(basePath, institutionId);
    return apiClient
      .get(`${path}/${deviceNumber}/monitor`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  fetchDeviceApps = (institutionId, deviceNumber, onSuccess, onError) => {
    let path = Util.formatString(basePath, institutionId);
    return apiClient
      .get(`${path}/${deviceNumber}/monitor/apps`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  fetchInstitutionMonitor = (institutionId, onSuccess, onError) => {
    let path = Util.formatString(basePath, institutionId);
    return apiClient
      .get(`${path}/monitor`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  registerDevice = (institutionId, payload, onSuccess, onError) => {
    let path = Util.formatString(basePath, institutionId);
    return apiClient
      .post(`${path}/register`, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  updateDeviceInfo = (institutionId, deviceId, payload, onSuccess, onError) => {
    let path = Util.formatString(basePath, institutionId);
    return apiClient
      .put(`${path}/${deviceId}/info`, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  disableDevice = (institutionId, deviceId, onSuccess, onError) => {
    let path = Util.formatString(basePath, institutionId);
    return apiClient
      .patch(`${path}/${deviceId}/disable`, {})
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };

  deleteDevice = (institutionId, deviceId, onSuccess, onError) => {
    let path = Util.formatString(basePath, institutionId);
    return apiClient
      .delete(`${path}/${deviceId}`, {})
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };
}

export default new DeviceService();
