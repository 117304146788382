/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDBadge from "components/MDBadge";

// @mui material components
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

import Form from "layouts/institution/tabs/devices/components/Modals/Form";
import Options from "layouts/institution/tabs/devices/components/Modals/Options";

export default function data(institutionId, list, updateData, deleteData) {
  const Device = ({ number, brand, model, status }) => (
    <MDBox
      display="flex"
      alignItems="center"
      lineHeight={1}
      component={Link}
      to={`${`/institutions/${institutionId}/devices/${status.toLowerCase()}/${number}`}`}
    >
      <Icon fontSize="medium" color="inherit">
        tablet_android
      </Icon>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          Device ID:&nbsp;{number}
        </MDTypography>
        <MDTypography display="block" variant="caption" fontWeight="regular">
          {`${brand !== null ? brand : ""} ${model !== null ? model : ""}`}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const User = ({ name }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="regular">
        {name}
      </MDTypography>
    </MDBox>
  );

  const Usage = ({ count }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="regular">
        {count} Apps&nbsp;
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "device", accessor: "device", width: "15%", align: "left" },
      { Header: "user", accessor: "user", width: "15%", align: "center" },
      { Header: "usage", accessor: "usage", align: "center" },
      { Header: "Subscription", accessor: "subscription", width: "15%", align: "center" },
      { Header: "action", accessor: "action", align: "center", disableSortBy: true },
    ],

    rows: list.map((item, i) => {
      return {
        device: (
          <Device number={item.number} brand={item.brand} model={item.model} status={item.status} />
        ),
        user: <User name={item.name} description={item.description} />,
        usage: <Usage count={item.apps_count} />,
        subscription: (
          <MDBox ml={1} lineHeight={1}>
            <MDTypography display="block" variant="caption" fontWeight="regular">
              {item.activated_at}
            </MDTypography>
            <MDTypography variant="caption" color="text" fontWeight="regular">
              {item.expired_at}
            </MDTypography>
          </MDBox>
        ),
        action: (
          <MDBox>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={6}>
                <Form
                  institutionId={institutionId}
                  data={item}
                  index={i}
                  updateData={(i, d) => updateData(i, d)}
                />
              </Grid>
              {item.status === "PENDING" && (
                <Grid item xs={12} md={6} xl={6}>
                  <Options
                    index={i}
                    deviceId={item.id}
                    institutionId={institutionId}
                    onComplete={(i) => deleteData(i)}
                    action={"delete"}
                  />
                </Grid>
              )}
            </Grid>
          </MDBox>
        ),
      };
    }),
  };
}
