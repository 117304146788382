import { memo, useState } from "react";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { useAuth } from "context/AuthContext";
import InstitutionService from "services/InstitutionService";
import { isEmpty } from "lodash";

const style = {
  width: 200,
  position: "absolute",
  top: "0",
  right: "0",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 1,
};

const inputsMap = {
  radius: "radius",
  latitude: "latitude",
  longitude: "longitude",
  address: "address",
};

function ControlPanel({
  defaultId,
  trackingId,
  center,
  radius,
  address,
  isTracking,
  isTrackingLoading,
  onRadiusChanged,
  onCenterChanged,
  onAddressChanged,
  onComplete,
  onTracking,
}) {
  const { useSessionExpired } = useAuth();

  const [message, setMessage] = useState();
  const [error, setError] = useState();
  const [radiusError, setRadiusError] = useState();
  const [latitudeError, setLatitudeError] = useState();
  const [longitudeError, setLongitudeError] = useState();
  const [addressError, setAddressError] = useState();
  const [submitting, setSubmitting] = useState(false);

  const submitForm = () => {
    resetErrors();
    let hasError = false;
    const latitude = center.lat;
    const longitude = center.lng;

    if (isNaN(radius) || radius < 0) {
      hasError = true;
      setRadiusError("Invalid geofencing radius");
    }

    if (latitude === 0) {
      hasError = true;
      setLatitudeError("Invalid location");
    }

    if (longitude === 0) {
      hasError = true;
      setLongitudeError("Invalid location");
    }

    if (isEmpty(address)) {
      hasError = true;
      setAddressError("Address is required");
    }

    if (!hasError) {
      setSubmitting(true);
      const payload = { radius, latitude, longitude, address };
      InstitutionService.updateLocation(
        defaultId,
        payload,
        (response) => {
          setSubmitting(false);
          if (response.data.success) {
            setMessage(response.data.message);
            onComplete(response.data.result);
          } else {
            setError(response.data.message);
          }
        },
        (error) => {
          setSubmitting(false);
          if (error?.response?.status == 401) {
            useSessionExpired();
          } else if (error?.response?.status >= 400 && error?.response?.status < 500) {
            setError(error?.response?.data?.message);
            if (error?.response?.status === 400) {
              const errors = error?.response?.data?.errors;
              if (errors) {
                if (inputsMap.radius in errors) setRadiusError(errors[inputsMap.radius]);
                if (inputsMap.latitude in errors) setLatitudeError(errors[inputsMap.latitude]);
                if (inputsMap.longitude in errors) setLongitudeError(errors[inputsMap.longitude]);
                if (inputsMap.address in errors) setAddressError(errors[inputsMap.address]);
              }
            }
          } else {
            setError("An error occured, please try again later");
          }
        }
      );
    } else {
      setError("You have one or more errors");
    }
  };

  const resetErrors = () => {
    setError();
    setMessage();
    setRadiusError();
    setLatitudeError();
    setLongitudeError();
    setAddressError();
  };

  return isTracking ? (
    <MDBox sx={style}>
      <MDBox mt={0}>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          gap={1.5}
        >
          <Grid container>
            <Grid item xs={12} md={12} xl={12}>
              <MDButton
                variant="gradient"
                color="dark"
                fullWidth
                onClick={() => onTracking(trackingId)}
                disabled={isTrackingLoading}
              >
                {isTrackingLoading ? "Please wait..." : "Get Current Location"}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  ) : (
    <MDBox sx={style}>
      <MDTypography variant="h6" noWrap mb={-1}>
        Geofencing
      </MDTypography>
      <MDTypography variant="p" noWrap sx={{ fontSize: 12 }}>
        Change the circle using radius:
      </MDTypography>
      {error && (
        <MDAlert color="error" dismissible>
          {error}
        </MDAlert>
      )}
      {message && (
        <MDAlert color="success" dismissible>
          {message}
        </MDAlert>
      )}
      <MDBox mt={1}>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          gap={1.5}
        >
          <MDBox display="flex" justifyContent="space-between">
            <MDInput
              type="number"
              label="Radius (Meters)"
              value={radius}
              min={0}
              fullWidth
              error={!isEmpty(radiusError)}
              onChange={(e) => onRadiusChanged(Number(e.target.value))}
            />
          </MDBox>
          {!isEmpty(radiusError) && (
            <MDBox mt={-2.5} mb={-1}>
              <MDTypography variant="caption" fontWeight="regular" color="error">
                {radiusError}
              </MDTypography>
            </MDBox>
          )}
          <MDBox display="flex" justifyContent="space-between">
            <MDInput
              type="number"
              label="Latitude"
              value={center ? center.lat : 0}
              disabled
              fullWidth
              error={!isEmpty(latitudeError)}
              onChange={(e) => onCenterChanged({ lat: Number(e.target.value), lng: center.lng })}
            />
          </MDBox>
          {!isEmpty(latitudeError) && (
            <MDBox mt={-2.5} mb={-1}>
              <MDTypography variant="caption" fontWeight="regular" color="error">
                {latitudeError}
              </MDTypography>
            </MDBox>
          )}
          <MDBox display="flex" justifyContent="space-between">
            <MDInput
              type="number"
              label="Longitude"
              value={center ? center.lng : 0}
              fullWidth
              disabled
              error={!isEmpty(longitudeError)}
              onChange={(e) => onCenterChanged({ lat: center.lat, lng: Number(e.target.value) })}
            />
          </MDBox>
          {!isEmpty(longitudeError) && (
            <MDBox mt={-2.5} mb={-1}>
              <MDTypography variant="caption" fontWeight="regular" color="error">
                {longitudeError}
              </MDTypography>
            </MDBox>
          )}
          <MDBox display="flex" justifyContent="space-between">
            <MDInput
              type="text"
              label="Address"
              value={address}
              fullWidth
              autoComplete="off"
              error={!isEmpty(addressError)}
              onChange={(e) => onAddressChanged(e.target.value)}
            />
          </MDBox>
          {!isEmpty(addressError) && (
            <MDBox mt={-2.5} mb={-1}>
              <MDTypography variant="caption" fontWeight="regular" color="error">
                {addressError}
              </MDTypography>
            </MDBox>
          )}
          <Grid container>
            <Grid item xs={12} md={12} xl={12}>
              <MDButton
                variant="gradient"
                color="dark"
                fullWidth
                onClick={submitForm}
                disabled={submitting}
              >
                {submitting ? "Please wait..." : "Save Changes"}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

ControlPanel.propTypes = {
  defaultId: PropTypes.string,
  trackingId: PropTypes.string,
  center: PropTypes.object,
  radius: PropTypes.number,
  address: PropTypes.string,
  isTracking: PropTypes.bool,
  isTrackingLoading: PropTypes.bool,
  onCenterChanged: PropTypes.func,
  onRadiusChanged: PropTypes.func,
  onAddressChanged: PropTypes.func,
  onComplete: PropTypes.func,
  onTracking: PropTypes.func,
};

export default memo(ControlPanel);
