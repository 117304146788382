/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Fragment, useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import AccountService from "services/AccountService";
import { useAuth } from "context/AuthContext";
import { isEmpty } from "lodash";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const inputsMap = {
  first_name: "first_name",
  last_name: "last_name",
  email: "email",
  roles: "roles",
};

function Form({ user, index, setShowForm, setSelectedIndex, onComplete }) {
  const { useSessionExpired } = useAuth();

  const [isUpdate, setIsUpdate] = useState(user ? true : false);
  const [resetPassword, setResetPassword] = useState(false);
  const [disableAccount, setDisableAccount] = useState(false);
  const [restoreAccount, setRestoreAccount] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState();
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState();
  const [roles, setRoles] = useState([]);
  const [rolesError, setRolesError] = useState();
  const [allRoles, setAllRoles] = useState([]);
  const [initialRoles, setInitialRoles] = useState(user && user.roles);
  const [message, setMessage] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchRoles();
    return () => {
      resetForm();
    };
  }, []);

  useEffect(() => {
    if (user) {
      setIsUpdate(true);
      if (user?.roles) setInitialRoles(user.roles);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
      setRoles(user.roles);
    } else resetForm();
  }, [user]);

  const onRoleSelected = (values) => {
    setRoles([...values]);
  };

  const fetchRoles = () => {
    setIsLoading(true);
    AccountService.fetchRoles(
      (response) => {
        setIsLoading(false);
        if (response.data.success) {
          setAllRoles(response.data.result);
        }
      },
      (error) => {
        setIsLoading(false);
        if (error?.response?.status == 401) useSessionExpired();
        else setError("An error occured, please try again later");
      }
    );
  };

  const submitForm = () => {
    resetErrors();
    let hasError = false;
    if (!resetPassword && !disableAccount) {
      if (isEmpty(firstName)) {
        hasError = true;
        setFirstNameError("First name is required");
      }
      if (isEmpty(lastName)) {
        hasError = true;
        setLastNameError("Last name is required");
      }
      if (isEmpty(email)) {
        hasError = true;
        setEmailError("Email address is required");
      }
    }
    if (!hasError) {
      const payload = {
        first_name: firstName,
        last_name: lastName,
        email,
        roles,
        enable: restoreAccount,
      };
      setIsSubmitting(true);
      if (isUpdate) {
        if (resetPassword) AccountService.resetPassword(user.id, onSuccess, onError);
        else if (disableAccount) AccountService.disableAccount(user.id, onSuccess, onError);
        else AccountService.updateInfo(user.id, payload, onSuccess, onError);
      } else AccountService.createUser(payload, onSuccess, onError);
    }
  };

  const onSuccess = (response) => {
    setIsSubmitting(false);
    if (response.data.success) {
      setMessage(response.data.message);
      const result = response.data.result;
      if (isUpdate) {
        if (!resetPassword) {
          onComplete(index, result);
          handleClose();
        }
      } else {
        resetForm();
        onComplete(result);
      }
    } else setError(response.data.message);
  };

  const onError = (error) => {
    setIsSubmitting(false);
    if (error?.response?.status == 401) {
      useSessionExpired();
    } else if (error?.response?.status >= 400 && error?.response?.status < 500) {
      setError(error?.response?.data?.message);
      if (error?.response?.status === 400) {
        const errors = error?.response?.data?.errors;
        if (errors) {
          if (inputsMap.first_name in errors) setFirstNameError(errors[inputsMap.first_name]);
          if (inputsMap.last_name in errors) setLastNameError(errors[inputsMap.last_name]);
          if (inputsMap.email in errors) setEmailError(errors[inputsMap.email]);
          if (inputsMap.roles in errors) setRolesError(errors[inputsMap.roles]);
        }
      }
    } else setError("An error occured, please try again later");
  };

  const handleClose = () => {
    resetForm();
    resetErrors();
    setSelectedIndex();
    setShowForm(false);
  };

  const resetErrors = () => {
    setError();
    setMessage();
    setFirstNameError();
    setLastNameError();
    setEmailError();
    setRolesError();
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setRoles([]);
    setInitialRoles([]);
  };

  return (
    <Card sx={{ width: "30%", position: "fixed", top: 125, right: 22 }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {`${isUpdate ? "Edit" : "New"} User`}
        </MDTypography>
      </MDBox>
      <MDBox p1={3} pb={2} px={2} component="form" role="form">
        <MDBox mb={1}>
          {error && (
            <MDAlert color="error" dismissible>
              {error}
            </MDAlert>
          )}
          {message && (
            <MDAlert color="success" dismissible>
              {message}
            </MDAlert>
          )}
        </MDBox>
        <Grid container spacing={1} mt={1} mb={2}>
          <Grid item xs={12} md={6} xl={6}>
            <MDBox>
              <MDInput
                type="text"
                label="First Name"
                value={firstName}
                required
                fullWidth
                autoComplete={"off"}
                disabled={resetPassword || disableAccount}
                error={!isEmpty(firstNameError)}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </MDBox>
            {!isEmpty(firstNameError) && (
              <MDBox mt={-1} mb={-1}>
                <MDTypography variant="caption" fontWeight="regular" color="error">
                  {lastNameError}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            <MDBox>
              <MDInput
                type="text"
                label="Last Name"
                value={lastName}
                required
                fullWidth
                autoComplete={"off"}
                disabled={resetPassword || disableAccount}
                error={!isEmpty(lastNameError)}
                onChange={(e) => setLastName(e.target.value)}
              />
            </MDBox>
            {!isEmpty(lastNameError) && (
              <MDBox mt={-1} mb={-1}>
                <MDTypography variant="caption" fontWeight="regular" color="error">
                  {lastNameError}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            <MDBox mt={2}>
              <MDInput
                type="text"
                label="Email Address"
                value={email}
                fullWidth
                required
                autoComplete={"off"}
                disabled={resetPassword || disableAccount}
                error={!isEmpty(emailError)}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            {!isEmpty(emailError) && (
              <MDBox mt={-1} mb={-1}>
                <MDTypography variant="caption" fontWeight="regular" color="error">
                  {emailError}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            <MDBox mt={2}>
              <Autocomplete
                multiple
                id="autocomplete-tags"
                options={allRoles}
                size="medium"
                loading={isLoading}
                disableCloseOnSelect
                // inputValue=""
                clearOnBlur={true}
                key={initialRoles}
                defaultValue={initialRoles}
                disabled={resetPassword || disableAccount}
                onChange={(event, value) => onRoleSelected(value)}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => `${option}`}
                renderOption={(props, option, { selected }) => (
                  // eslint-disable-next-line
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {`${option}`}
                  </li>
                )}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    type="text"
                    label="Roles"
                    placeholder="Search and select role(s)"
                    fullWidth
                    disabled={resetPassword || disableAccount}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            </MDBox>
            {!isEmpty(rolesError) && (
              <MDBox mt={-1} mb={-1}>
                <MDTypography variant="caption" fontWeight="regular" color="error">
                  {rolesError}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
          {isUpdate ? (
            <>
              <Grid item xs={12} md={6} xl={6}>
                <MDBox display="flex" alignItems="center" mb={-2} ml={-1.5}>
                  <MDBox mt={0.5}>
                    <Switch
                      disabled={disableAccount}
                      checked={resetPassword}
                      onChange={() => setResetPassword((resetPassword) => !resetPassword)}
                    />
                  </MDBox>
                  <MDBox>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      Reset Password
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <MDBox display="flex" alignItems="center" mb={-2} ml={-1.5}>
                  <MDBox mt={0.5}>
                    <Switch
                      disabled={resetPassword}
                      checked={disableAccount}
                      onChange={() => setDisableAccount((disableAccount) => !disableAccount)}
                    />
                  </MDBox>
                  <MDBox>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      Disable Account
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={12} xl={12}>
              <MDBox display="flex" alignItems="center" mb={-2} ml={-1.5}>
                <MDBox mt={0.5}>
                  <Switch
                    checked={restoreAccount}
                    onChange={() => setRestoreAccount((restoreAccount) => !restoreAccount)}
                  />
                </MDBox>
                <MDBox>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    Restore account if already exists
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          )}
          <Grid item xs={12} md={6} xl={6} mt={2}>
            <MDButton
              variant="gradient"
              color="light"
              fullWidth
              onClick={() => handleClose()}
              disabled={isSubmitting}
            >
              Close
            </MDButton>
          </Grid>
          <Grid item xs={12} md={6} xl={6} mt={2} textAlign={"right"}>
            <MDButton
              variant="gradient"
              color="dark"
              fullWidth
              onClick={submitForm}
              disabled={isSubmitting}
            >
              {isSubmitting
                ? "Please wait..."
                : isUpdate
                ? resetPassword || disableAccount
                  ? "Continue"
                  : "Save Changes"
                : "Create User"}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

Form.propTypes = {
  user: PropTypes.object,
  index: PropTypes.number,
  addData: PropTypes.func,
  onComplete: PropTypes.func,
  setShowForm: PropTypes.func,
  setSelectedIndex: PropTypes.func,
};

export default Form;
