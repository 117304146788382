/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Login from "layouts/auth/login";
import Dashboard from "layouts/dashboard";
import Institutions from "layouts/institutions";
import Institution from "layouts/institution";
import Accounts from "layouts/accounts";
import Settings from "layouts/settings";
import Password from "layouts/profile/Password";

import CircularProgress from "@mui/material/CircularProgress";

import MDAuthError from "components/MDError/MDAuthError";

// @mui icons
import Icon from "@mui/material/Icon";

import { AllowedAccess } from "react-permission-role";

const publicRoutes = [
  {
    type: "collapse",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <Login />,
  },
];

const privateRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    roles: ["SUPER ADMIN", "ADMIN"],
    component: (
      <AllowedAccess
        roles={["SUPER ADMIN", "ADMIN"]}
        isLoading={<CircularProgress color="inherit" size={20} />}
        renderAuthFailed={<MDAuthError />}
      >
        <Dashboard />
      </AllowedAccess>
    ),
  },
  {
    type: "collapse",
    name: "Institutions",
    key: "institutions",
    icon: <Icon fontSize="small">meeting_room</Icon>,
    route: "/institutions",
    roles: ["SUPER ADMIN", "ADMIN"],
    component: (
      <AllowedAccess
        roles={["SUPER ADMIN", "ADMIN"]}
        isLoading={<CircularProgress color="inherit" size={20} />}
        renderAuthFailed={<MDAuthError />}
      >
        <Institutions />
      </AllowedAccess>
    ),
  },
  {
    type: "collapse",
    name: "Accounts",
    key: "accounts",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/accounts",
    roles: ["SUPER ADMIN", "ADMIN"],
    component: (
      <AllowedAccess
        roles={["SUPER ADMIN", "ADMIN"]}
        isLoading={<CircularProgress color="inherit" size={20} />}
        renderAuthFailed={<MDAuthError />}
      >
        <Accounts />
      </AllowedAccess>
    ),
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    roles: ["SUPER ADMIN", "ADMIN"],
    roles: ["SUPER ADMIN", "ADMIN"],
    component: (
      <AllowedAccess
        roles={["SUPER ADMIN", "ADMIN"]}
        isLoading={<CircularProgress color="inherit" size={20} />}
        renderAuthFailed={<MDAuthError />}
      >
        <Settings />
      </AllowedAccess>
    ),
  },
  {
    type: "none",
    name: "Institutions",
    key: "institutions",
    icon: <Icon fontSize="small">meeting_room</Icon>,
    route: "/institutions/:status",
    roles: ["SUPER ADMIN", "ADMIN"],
    component: (
      <AllowedAccess
        roles={["SUPER ADMIN", "ADMIN"]}
        isLoading={<CircularProgress color="inherit" size={20} />}
        renderAuthFailed={<MDAuthError />}
      >
        <Institutions />
      </AllowedAccess>
    ),
  },
  {
    type: "none",
    name: "Institution",
    key: "institutions",
    icon: <Icon fontSize="small">meeting_room</Icon>,
    route: "/institutions/:id/:tab",
    roles: ["SUPER ADMIN", "ADMIN", "MANAGER"],
    component: (
      <AllowedAccess
        roles={["SUPER ADMIN", "ADMIN", "MANAGER"]}
        isLoading={<CircularProgress color="inherit" size={20} />}
        renderAuthFailed={<MDAuthError />}
      >
        <Institution />
      </AllowedAccess>
    ),
  },
  {
    type: "none",
    name: "Institution",
    key: "institutions",
    icon: <Icon fontSize="small">meeting_room</Icon>,
    route: "/institutions/:id/:tab/:status",
    roles: ["SUPER ADMIN", "ADMIN", "MANAGER"],
    component: (
      <AllowedAccess
        roles={["SUPER ADMIN", "ADMIN", "MANAGER"]}
        isLoading={<CircularProgress color="inherit" size={20} />}
        renderAuthFailed={<MDAuthError />}
      >
        <Institution />
      </AllowedAccess>
    ),
  },
  {
    type: "none",
    name: "Institution Device",
    key: "institutions",
    icon: <Icon fontSize="small">meeting_room</Icon>,
    route: "/institutions/:id/:tab/:status/:number",
    roles: ["SUPER ADMIN", "ADMIN", "MANAGER"],
    component: (
      <AllowedAccess
        roles={["SUPER ADMIN", "ADMIN", "MANAGER"]}
        isLoading={<CircularProgress color="inherit" size={20} />}
        renderAuthFailed={<MDAuthError />}
      >
        <Institution />
      </AllowedAccess>
    ),
  },
  {
    type: "none",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">account</Icon>,
    route: "/profile/password",
    roles: [],
    component: <Password />,
  },
];

export { publicRoutes, privateRoutes };
