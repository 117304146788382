import { memo, useEffect } from "react";
import { useMap } from "@vis.gl/react-google-maps";

import PropTypes from "prop-types";

const PlacesHandler = ({ place }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place) return;

    if (place.geometry?.viewport) {
      console.log(place.geometry?.viewport);
      map.fitBounds(place.geometry?.viewport);
    }
  }, [map, place]);

  return null;
};

PlacesHandler.propTypes = {
  place: PropTypes.object,
};

export default memo(PlacesHandler);
