import apiClient from "config/api";
const path = "/admin/profile";

class ProfileService {
  changePassword = (payload, onSuccess, onError) => {
    return apiClient
      .put(`${path}/password`, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  };
}

export default new ProfileService();
