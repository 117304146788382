/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import UtilService from "services/UtilService";
import { setPageError, useMaterialUIController } from "context/UIContext";
import { useAuth } from "context/AuthContext";
import Util from "helper/Util";

function Dashboard() {
  const [controller, dispatch] = useMaterialUIController();
  const { isAuthenticated, useSessionExpired } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [institutionsCount, setInstitutionsCount] = useState();
  const [devicesAppsCount, setDevicesAppsCount] = useState();
  const [allocatedDevicesCount, setAllocatedDevicesCount] = useState();
  const [activeDevicesCount, setActiveDevicesCount] = useState();
  const [pendingDevicesCount, setPendingDevicesCount] = useState();
  const [inactiveDevicesCount, setInactiveDevicesCount] = useState();
  const [expiredDevicesCount, setExpiredDevicesCount] = useState();
  const [institutionsLastUpdated, setInstitutionsLastUpdated] = useState();
  const [activeDevicesLastUpdated, setActiveDevicesLastUpdated] = useState();
  const [pendingDevicesLastUpdated, setPendingDevicesLastUpdated] = useState();
  const [inactiveDevicesLastUpdated, setInactiveDevicesLastUpdated] = useState();
  const [expiredDevicesLastUpdated, setExpiredDevicesLastUpdated] = useState();
  const [devicesAppsLastUpdated, setDevicesAppsLastUpdated] = useState();
  const [allocatedDevicesLastUpdated, setAllocatedDevicesLastUpdated] = useState();
  const [subscription, setSubscription] = useState();
  const [subscriptionLastUpdated, setSubscriptionLastUpdated] = useState();
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      UtilService.fetchStats(onFetchSuccess, onFetchError);
    }
  }, [isAuthenticated]);

  const onFetchSuccess = (response) => {
    setIsLoading(false);
    if (response.data.success) {
      const result = response.data.result;
      setInstitutionsCount(result.counts.institutions);
      setDevicesAppsCount(result.counts.devices_apps);
      setActiveDevicesCount(result.counts.active_devices);
      setAllocatedDevicesCount(result.counts.allocated_devices);
      setPendingDevicesCount(result.counts.pending_devices);
      setInactiveDevicesCount(result.counts.inactive_devices);
      setExpiredDevicesCount(result.counts.expired_devices);
      setInstitutionsLastUpdated(result.updated_at.institutions);
      setActiveDevicesLastUpdated(result.updated_at.active_devices);
      setPendingDevicesLastUpdated(result.updated_at.pending_devices);
      setInactiveDevicesLastUpdated(result.updated_at.inactive_devices);
      setExpiredDevicesLastUpdated(result.updated_at.expired_devices);
      setDevicesAppsLastUpdated(result.updated_at.devices_apps);
      setAllocatedDevicesLastUpdated(result.updated_at.allocated_devices);
      setSubscription(result.counts.subscription);
      setSubscriptionLastUpdated(result.updated_at.subscription);
      let data = {
        labels: result.apps_labels,
        datasets: { label: "Apps", data: result.apps_counts },
      };
      setChartData(data);
    } else setPageError(dispatch, response.data.error);
  };

  const onFetchError = (error) => {
    setIsLoading(false);
    if (error?.response?.status == 401) useSessionExpired();
    else setPageError(dispatch, "An error occurred, please try again later");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="corporate_fare"
                title="Institutions"
                count={institutionsCount ? Util.formatNumber(institutionsCount, 0) : 0}
                link={{
                  url: "",
                  text: "",
                }}
                percentage={{
                  color: "success",
                  amount: "",
                  label: isLoading
                    ? "Loading..."
                    : institutionsLastUpdated
                    ? `Updated ${institutionsLastUpdated}`
                    : "No updates",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="apps"
                title="Devices Apps"
                count={devicesAppsCount ? Util.formatNumber(devicesAppsCount, 0) : 0}
                link={{
                  url: "",
                  text: "",
                }}
                percentage={{
                  color: "success",
                  amount: "",
                  label: isLoading
                    ? "Loading..."
                    : devicesAppsLastUpdated
                    ? `Updated ${devicesAppsLastUpdated}`
                    : "No updates",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="offline_share"
                title="Allocated Devices"
                count={allocatedDevicesCount ? Util.formatNumber(allocatedDevicesCount, 0) : 0}
                link={{
                  url: "",
                  text: "",
                }}
                percentage={{
                  color: "success",
                  amount: "",
                  label: isLoading
                    ? "Loading..."
                    : allocatedDevicesLastUpdated
                    ? `Updated ${allocatedDevicesLastUpdated}`
                    : "No updates",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="loyalty"
                title="Subscription"
                count={subscription ? Util.formatNumber(subscription, 0) : 0}
                link={{
                  url: "",
                  text: "",
                }}
                percentage={{
                  color: "success",
                  amount: "",
                  label: isLoading
                    ? "Loading..."
                    : subscriptionLastUpdated
                    ? `Updated ${subscriptionLastUpdated}`
                    : "No updates",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="phonelink_ring"
                title="Active Devices"
                count={activeDevicesCount ? Util.formatNumber(activeDevicesCount, 0) : 0}
                link={{
                  url: "",
                  text: "",
                }}
                percentage={{
                  color: "success",
                  amount: "",
                  label: isLoading
                    ? "Loading..."
                    : activeDevicesLastUpdated
                    ? `Updated ${activeDevicesLastUpdated}`
                    : "No updates",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="device_unknown"
                title="Pending Devices"
                count={pendingDevicesCount ? Util.formatNumber(pendingDevicesCount, 0) : 0}
                link={{
                  url: "",
                  text: "",
                }}
                percentage={{
                  color: "success",
                  amount: "",
                  label: isLoading
                    ? "Loading..."
                    : pendingDevicesLastUpdated
                    ? `Updated ${pendingDevicesLastUpdated}`
                    : "No updates",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="secondary"
                icon="mobile_off"
                title="Inactive Devices"
                count={inactiveDevicesCount ? Util.formatNumber(inactiveDevicesCount, 0) : 0}
                link={{
                  url: "",
                  text: "",
                }}
                percentage={{
                  color: "success",
                  amount: "",
                  label: isLoading
                    ? "Loading..."
                    : inactiveDevicesLastUpdated
                    ? `Updated ${inactiveDevicesLastUpdated}`
                    : "No updates",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="phonelink_erase"
                title="Expired Devices"
                count={expiredDevicesCount ? Util.formatNumber(expiredDevicesCount, 0) : 0}
                link={{
                  url: "",
                  text: "",
                }}
                percentage={{
                  color: "success",
                  amount: "",
                  label: isLoading
                    ? "Loading..."
                    : expiredDevicesLastUpdated
                    ? `Updated ${expiredDevicesLastUpdated}`
                    : "No updates",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="secondary"
                  title="Apps Usage"
                  description={<></>}
                  date={devicesAppsLastUpdated ? `Updated ${devicesAppsLastUpdated}` : "No updates"}
                  chart={chartData}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
