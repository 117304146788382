import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";

import MDPagination from "components/MDPagination";

import { usePagination, DOTS } from "./usePagination";
import MDBox from "components/MDBox";

const MDDotPagination = (props) => {
  const { setCurrentPage, totalCount, siblingCount, currentPage, pageSize } = props;

  const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    setCurrentPage(currentPage + 1 - 1);
  };

  const onPrevious = () => {
    setCurrentPage(currentPage - 1 - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <MDPagination variant={"gradient"} color={"info"}>
      {currentPage !== 1 && (
        <MDPagination item onClick={onPrevious}>
          <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
        </MDPagination>
      )}
      {paginationRange.map((pageNumber) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          // return <li className="pagination-item dots">&#8230;</li>;
          return (
            <MDPagination
              item
              key={pageNumber}
              onClick={() => setCurrentPage()}
              active={currentPage === pageNumber}
            >
              &#8230;
            </MDPagination>
          );
        }
        // Render our Page Pills
        return (
          <MDPagination
            item
            key={pageNumber}
            onClick={() => setCurrentPage(pageNumber - 1)}
            active={currentPage === pageNumber}
          >
            {pageNumber}
          </MDPagination>
        );
      })}
      {currentPage !== lastPage && (
        <MDPagination item onClick={onNext} disabled={currentPage === lastPage}>
          <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
        </MDPagination>
      )}
    </MDPagination>
  );
};

MDDotPagination.defaultProps = {
  siblingCount: 1,
};

MDDotPagination.propTypes = {
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  siblingCount: PropTypes.number,
  setCurrentPage: PropTypes.func,
};

export default MDDotPagination;
