const environment = "production";
module.exports = {
  brandName: "PlatformsMDM",
  googleMapID: "28e90be7e3408ed2",
  googleAPIKey: "AIzaSyBzJ7lnIT9JchXZoAmSF7gwkGxaIGMuSRU",
  pieSocketClusterId: "free.blr2",
  pieSocketAPIKey: "ZI24Et3fBdm9kDEccsEZyP3LItCQpN1RctNzebLJ",
  // pieSocketAPIKey: "pk290cb8bQzWVKi3LWxXzxPEtJhTn1GMVTqcaadL",
  baseUrl:
    environment === "production"
      ? "https://gateway.prod.mdm.platformsmdm.com.ng/api/v1"
      : "http://localhost:9000/api/v1",
  webSocketAuthUrl:
    environment === "production"
      ? "https://gateway.prod.mdm.platformsmdm.com.ng/api/v1/main/util/ws/auth"
      : "http://localhost:9000/api/v1/main/util/ws/auth",
};
