import { memo, useEffect, useState, useCallback } from "react";

import PropTypes from "prop-types";

// react-routers components
import { useParams } from "react-router-dom";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDMaps from "components/MDMaps";

import { useAuth } from "context/AuthContext";
import DeviceService from "services/DeviceService";
import { setPageError, useMaterialUIController } from "context/UIContext";
// import { loadTreeDataset } from "./data/trees";

const DATA_LIMIT = 100;

function Maps({ institution }) {
  const { useSessionExpired } = useAuth();
  const [controller, dispatch] = useMaterialUIController();

  const { status } = useParams();
  const match = /^\d{15}$/.test(status);
  const deviceNumber = status;
  const [device, setDevice] = useState();
  const [devices, setDevices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(0);
    if (match) {
      setLocations([]);
      fetchDeviceInfo();
      fetchDeviceLocations();
    } else {
      setDevices([]);
      fetchDevices(0);
    }
  }, [institution]);

  useEffect(() => {
    if (offset > 0) {
      if (match) {
        fetchMoreDeviceLocations(offset);
      } else {
        fetchDevices(offset);
      }
    }
  }, [offset]);

  // const [trees, setTrees] = useState([]);
  // load data asynchronously
  // useEffect(() => {
  // loadTreeDataset().then((data) => setDevices(data));
  // }, []);

  const updateDeviceLocation = (position) => {
    let d = device;
    d.position = position;
    setDevice(d);
  };

  const fetchDeviceInfo = () => {
    DeviceService.fetchDeviceInfo(
      institution.id,
      deviceNumber,
      (response) => {
        if (response.data.success) {
          setDevice(response.data.result);
        } else setPageError(dispatch, response.data.message);
      },
      onError
    );
  };

  const fetchDeviceLocations = () => {
    DeviceService.fetchDeviceLocations(
      institution.id,
      deviceNumber,
      offset,
      DATA_LIMIT,
      (response) => {
        if (response.data.success) {
          setLocations((locations) => [...locations, ...response.data.result.locations]);
          const isLast = response.data.result.is_last;
          if (!isLast) setOffset((offset) => offset + 1);
        }
      },
      onError
    );
  };

  const fetchMoreDeviceLocations = useCallback((offset) => {
    DeviceService.fetchDeviceLocations(
      institution.id,
      deviceNumber,
      offset,
      DATA_LIMIT,
      (response) => {
        if (response.data.success) {
          setLocations((locations) => [...locations, ...response.data.result.locations]);
          const isLast = response.data.result.is_last;
          if (!isLast) setOffset((offset) => offset + 1);
        }
      },
      onError
    );
  });

  // const fetchDevice = useMemo(() => async () => {
  //   try {
  //     let response = await DeviceService.fetchDeviceInfo(institution.id, deviceNumber);
  //     setDevice(response.data.result);
  //   } catch (err) {
  //     if (err?.response?.status == 401) useSessionExpired();
  //   }
  // });

  const fetchDevices = useCallback((offset) => {
    DeviceService.fetchLocations(
      institution.id,
      offset,
      DATA_LIMIT,
      (response) => {
        if (response.data.success) {
          setDevices((devices) => [...devices, ...response.data.result.devices]);
          const isLast = response.data.result.is_last;
          if (!isLast) setOffset((offset) => offset + 1);
        } else setPageError(dispatch, response.data.message);
      },
      onError
    );
  });

  const onError = (error) => {
    if (error?.response?.status == 401) useSessionExpired();
    else setPageError(dispatch, "An error occurred, please try again later");
  };

  return (
    <MDBox mt={2} mb={1} sx={{ height: 400 }}>
      <Card>
        {match ? (
          device && (
            <MDMaps
              isTracking={true}
              defaultLocation={institution}
              trackingLocation={device}
              updateTrackingLocation={updateDeviceLocation}
              locations={locations}
              isClustered={!match}
              isGeofenced={true}
              showInfoWindow={true}
            />
          )
        ) : (
          <MDMaps
            defaultLocation={institution}
            locations={devices}
            isClustered={true}
            isGeofenced={true}
            showInfoWindow={true}
          />
        )}
      </Card>
    </MDBox>
  );
}

Maps.propTypes = {
  institution: PropTypes.object,
};

export default memo(Maps);
