import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Fade from "@mui/material/Fade";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Tooltip from "@mui/material/Tooltip";
import MDTypography from "components/MDTypography";

import MDMaps from "components/MDMaps";
import MDContentModal from "components/MDModal/MDContentModal";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context/UIContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
};

function Form({ data, onComplete }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleComplete = (data) => {
    onComplete(data);
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    if (reason === "escapeKeyDown") {
      return false;
    }
    setOpen(false);
  };

  const Button = () => (
    <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={handleOpen}>
      <Tooltip title={"Edit Location"} placement="top">
        <Icon>edit</Icon>
      </Tooltip>
    </MDButton>
  );

  return (
    <div>
      <MDContentModal
        isOpen={open}
        setClose={() => setOpen(false)}
        button={<Button handleOpen={() => setOpen(true)} />}
      >
        <Fade in={open}>
          <Card sx={style}>
            <MDBox variant="gradient" bgColor="dark" p={2} textAlign="center">
              <MDTypography variant="h5" fontWeight="regular" color="white">
                Set Location
              </MDTypography>
              <MDBox
                position="absolute"
                top={16}
                right={16}
                zIndex={99}
                color="white"
                sx={{ cursor: "pointer" }}
                onClick={() => handleClose()}
              >
                <Icon fontSize="small" color="inherit">
                  close
                </Icon>
              </MDBox>
            </MDBox>
            <MDBox>
              <MDMaps
                defaultLocation={data}
                updateDefaultLocation={handleComplete}
                disableDefaultUI={true}
                isSearchable={true}
                isGeofenced={true}
                isDraggable={true}
                allowLocationChange={true}
              />
            </MDBox>
          </Card>
        </Fade>
      </MDContentModal>
    </div>
  );
}

Form.propTypes = {
  data: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Form;
