import { useState, useEffect } from "react";

// react-routers components
import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Monitor from "layouts/institution/tabs/monitor";
import Devices from "layouts/institution/tabs/devices";
import Maps from "layouts/institution/tabs/maps";
import Manager from "layouts/institution/tabs/manager";
import Device from "layouts/institution/tabs/devices/device";

import breakpoints from "assets/theme/base/breakpoints";

import InstitutionService from "services/InstitutionService";
import { useAuth } from "context/AuthContext";
import { setPageError, useMaterialUIController } from "context/UIContext";

const tabsMap = {
  monitor: 0,
  devices: 1,
  maps: 2,
  manager: 3,
};

function Insitution() {
  const navigate = useNavigate();
  const { useSessionExpired } = useAuth();
  const [controller, dispatch] = useMaterialUIController();

  const { id, tab, number } = useParams();

  const numberMatch = /^\d{15}$/.test(number);
  const tabMatch = /(monitor|devices|maps|manager)/.test(tab);
  const tabValue =
    numberMatch && Object.keys(tabsMap).includes(tab) ? tab : tab && tabMatch ? tab : "monitor";

  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [data, setData] = useState();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabIndex, setTabIndex] = useState(
    Object.keys(tabsMap).includes(tabValue) ? tabsMap[tabValue] : 0
  );

  useEffect(() => {
    if (!tabValue) navigate(`/institutions/${id}/monitor`);
    setTabIndex(Object.keys(tabsMap).includes(tabValue) ? tabsMap[tabValue] : 0);
  }, [tabValue]);

  useEffect(() => {
    fetchDetails();
  }, [id]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabIndex(tabsMap[newValue]);
  };

  const fetchDetails = () => {
    setIsLoading(true);
    InstitutionService.fetchDetails(
      id,
      (response) => {
        setIsLoading(false);
        if (response.data.success) {
          const institution = response.data.result;
          setData(institution);
          setTitle(institution.name);
        } else setPageError(dispatch, response.data.message);
      },
      (error) => {
        setIsLoading(false);
        if (error?.response?.status == 401) useSessionExpired();
        else setPageError(dispatch, "An error occurred, please try again later");
      }
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={2}>
        <MDTypography fontWeight="bold" textTransform="capitalize" variant="h6" noWrap>
          {title}
        </MDTypography>
      </MDBox>
      {isLoading ? (
        <MDBox display="flex" justifyContent="center" alignItems="center" sx={{ height: 200 }}>
          <CircularProgress color="inherit" size={20} />
        </MDBox>
      ) : (
        <MDBox mt={3} mb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={6} lg={6}>
                      <AppBar position="static">
                        <Tabs
                          orientation={tabsOrientation}
                          value={numberMatch ? "devices" : tab && tabMatch ? tab : "monitor"}
                          onChange={handleSetTabValue}
                        >
                          <Tab
                            label="Monitor"
                            value="monitor"
                            icon={
                              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                analytics
                              </Icon>
                            }
                            onClick={() => navigate(`/institutions/${id}/monitor`)}
                          />
                          <Tab
                            label="Devices"
                            value="devices"
                            icon={
                              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                phone_android
                              </Icon>
                            }
                            onClick={() => navigate(`/institutions/${id}/devices`)}
                          />
                          <Tab
                            label="Maps"
                            value="maps"
                            icon={
                              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                map
                              </Icon>
                            }
                            onClick={() => navigate(`/institutions/${id}/maps`)}
                          />
                          <Tab
                            label="Manager"
                            value="manager"
                            icon={
                              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                manage_accounts
                              </Icon>
                            }
                            onClick={() => navigate(`/institutions/${id}/manager`)}
                          />
                        </Tabs>
                      </AppBar>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
          {data &&
            (numberMatch ? (
              <Device institutionId={id} deviceNumber={number} />
            ) : (
              <>
                {tabIndex == 0 && <Monitor institutionId={id} />}
                {tabIndex == 1 && <Devices institutionId={id} />}
                {tabIndex == 2 && <Maps institution={data} />}
                {tabIndex == 3 && <Manager data={data} setData={setData} />}
              </>
            ))}
        </MDBox>
      )}
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Insitution;
