import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";

function MDContentModal({ button, children, isOpen, setClose }) {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    if (reason === "escapeKeyDown") {
      return false;
    }
    setOpen(false);
    setClose(true);
  };

  return (
    <div>
      {button}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        disableEscapeKeyDown
      >
        {children}
      </Modal>
    </div>
  );
}

MDContentModal.defaulProps = {
  isOpen: false,
};

MDContentModal.propTypes = {
  button: PropTypes.node.isRequired,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  setClose: PropTypes.func,
};

export default MDContentModal;
