/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDDotPagination from "components/MDPagination/MDDotPagination";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context/UIContext";

import User from "layouts/accounts/components/User";
import { debounce } from "lodash";

function Users({
  data,
  pageSize,
  totalCount,
  isPermitted,
  showForm,
  setShowForm,
  setSelectedIndex,
  currentPage,
  setCurrentPage,
  setSearchKeyword,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const openUserForm = () => {
    setSelectedIndex();
    setShowForm(true);
  };

  const onSearchChange = debounce((value) => {
    setSearchKeyword(value || undefined);
  }, 1000);

  return (
    <Card id="users-list">
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Users
        </MDTypography>
        {isPermitted && (
          <MDBox width="12rem">
            <MDInput
              placeholder={"Search..."}
              size="small"
              fullWidth
              autoComplete="off"
              onChange={({ currentTarget }) => {
                onSearchChange(currentTarget.value);
              }}
            />
          </MDBox>
        )}
        {!showForm && isPermitted && (
          <MDBox display="flex" alignItems="flex-end">
            <MDButton
              variant="text"
              color={darkMode ? "white" : "dark"}
              onClick={() => openUserForm()}
              sx={{ minHeight: 20, minWidth: 20, p: 1 }}
            >
              <Tooltip title={"Create User"} placement="top">
                <Icon>person_add</Icon>
              </Tooltip>
            </MDButton>
          </MDBox>
        )}
      </MDBox>
      <MDBox pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {data &&
            data.map((user, i) => (
              <User
                key={i}
                index={i}
                data={user}
                isPermitted={isPermitted}
                setShowForm={setShowForm}
                setSelectedIndex={setSelectedIndex}
                noGutter
              />
            ))}
        </MDBox>
        <MDBox
          mt={3}
          display="flex"
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems={"flex-end"}
        >
          <MDBox ml={"auto"}>
            <MDDotPagination
              currentPage={currentPage + 1}
              setCurrentPage={setCurrentPage}
              totalCount={totalCount}
              pageSize={pageSize}
              siblingCount={1}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Users.defaultProps = {
  showForm: false,
};

Users.propTypes = {
  isLast: PropTypes.bool,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  showForm: PropTypes.bool,
  isPermitted: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  setShowForm: PropTypes.func,
  setSelectedIndex: PropTypes.func,
  setSearchKeyword: PropTypes.func,
};

export default Users;
