import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { InfoWindow, useMap } from "@vis.gl/react-google-maps";
import ClusterMarker from "components/MDMaps/components/Marker/ClusterMarker";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const ClusterMarkers = ({ isTracking, locations, showInfoWindow }) => {
  const [markers, setMarkers] = useState({});
  const [selectedLocationKey, setSelectedLocationKey] = useState(null);

  const selectedLocation = useMemo(() => {
    if (locations && selectedLocationKey) {
      return locations.find((l) => l.key === selectedLocationKey);
    }
    return null;
  }, [locations, selectedLocationKey]);

  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;
    return new MarkerClusterer({ map });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;
    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  const setMarkerRef = useCallback((marker, key) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key])) {
        return markers;
      }
      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;
        return newMarkers;
      }
    });
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedLocationKey(null);
  }, []);

  const handleMarkerClick = useCallback((location) => {
    setSelectedLocationKey(location.key);
  }, []);

  return (
    <>
      {locations &&
        locations.map((location) => (
          <ClusterMarker
            key={location.key}
            position={location}
            isTracking={isTracking}
            onClick={handleMarkerClick}
            setMarkerRef={setMarkerRef}
            showInfoWindow={showInfoWindow}
          />
        ))}

      {showInfoWindow && selectedLocation && (
        <InfoWindow anchor={markers[selectedLocationKey]} onCloseClick={handleInfoWindowClose}>
          <MDBox variant="gradient">
            <MDTypography variant="h6" noWrap sx={{ fontSize: 14 }}>
              {selectedLocation?.title}
            </MDTypography>
            <MDTypography variant="p" noWrap sx={{ fontSize: 12 }}>
              {selectedLocation?.content}
            </MDTypography>
          </MDBox>
        </InfoWindow>
      )}
    </>
  );
};

ClusterMarkers.defaultProps = {
  isTracking: false,
};

ClusterMarkers.propTypes = {
  locations: PropTypes.array,
  isTracking: PropTypes.bool,
  showInfoWindow: PropTypes.bool,
};

export default ClusterMarkers;
