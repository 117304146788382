import React, { useCallback } from "react";
import { AdvancedMarker } from "@vis.gl/react-google-maps";

import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import mapPinRed from "assets/images/map-pin-red.png";
import mapPinBlue from "assets/images/map-pin-blue.png";

const ClusterMarker = (props) => {
  const { position, isTracking, onClick, setMarkerRef } = props;

  const handleClick = useCallback(() => onClick(position), [onClick, position]);
  const ref = useCallback(
    (marker) => setMarkerRef(marker, position.key),
    [setMarkerRef, position.key]
  );

  return (
    <AdvancedMarker position={position.position} ref={ref} onClick={handleClick}>
      <MDBox>
        <MDBox component="img" src={isTracking ? mapPinBlue : mapPinRed} width={30} height={30} />
      </MDBox>
    </AdvancedMarker>
  );
};

ClusterMarker.defaultProps = {
  isTracking: false,
};

ClusterMarker.propTypes = {
  position: PropTypes.object,
  isTracking: PropTypes.bool,
  onClick: PropTypes.func,
  setMarkerRef: PropTypes.func,
};

export default ClusterMarker;
