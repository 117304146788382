/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

import { usePermission } from "react-permission-role";

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Billing page components
import Users from "layouts/accounts/components/Users";
import Form from "layouts/accounts/components/Form";

import AccountService from "services/AccountService";
import { setPageError, useMaterialUIController } from "context/UIContext";
import { useAuth } from "context/AuthContext";

import { isEmpty } from "lodash";

const DATA_LIMIT = 10;

function Accounts() {
  const enabled = true;
  const { useSessionExpired } = useAuth();
  const [controller, dispatch] = useMaterialUIController();
  const { isAuthorized } = usePermission();
  const [isPermitted, setIsPermitted] = useState(false);

  const [users, setUsers] = useState();
  const [showForm, setShowForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLast, setIsLast] = useState(true);

  useEffect(() => {
    const checkPermission = async () => {
      if (await isAuthorized(["SUPER ADMIN"], "")) {
        setIsPermitted(true);
      }
    };
    checkPermission();
    if (isPermitted) {
      if (isSearching) {
        AccountService.searchUsers(
          searchKeyword,
          enabled,
          0,
          DATA_LIMIT,
          onFetchSuccess,
          onFetchError
        );
      } else {
        setIsLoading(true);
        AccountService.fetchUsers(enabled, 0, DATA_LIMIT, onFetchSuccess, onFetchError);
      }
    }
  }, [isPermitted]);

  useEffect(() => {
    if (Number(selectedIndex) >= 0) {
      setSelectedUser(users[selectedIndex]);
      setShowForm(true);
    } else setSelectedUser();
  }, [selectedIndex]);

  useEffect(() => {
    if (isLoaded) {
      if (isSearching) {
        AccountService.searchUsers(
          searchKeyword,
          enabled,
          currentPage,
          DATA_LIMIT,
          onFetchSuccess,
          onFetchError
        );
      } else {
        AccountService.fetchUsers(enabled, currentPage, DATA_LIMIT, onFetchSuccess, onFetchError);
      }
    }
  }, [currentPage, isSearching]);

  useEffect(() => {
    if (isLoaded) {
      if (!isEmpty(searchKeyword) && searchKeyword.trim().length >= 3) {
        setCurrentPage(0);
        setIsSearching(true);
      } else if (isEmpty(searchKeyword)) {
        setCurrentPage(0);
        setIsSearching(false);
      }
    }
  }, [searchKeyword]);

  const onFetchSuccess = (response) => {
    setIsLoading(false);
    setIsLoaded(true);
    if (response.data.success) {
      setUsers(response.data.result.users);
      setTotalCount(response.data.result.total);
      setIsLast(response.data.result.is_last);
    } else setPageError(dispatch, response.data.message);
  };

  const onFetchError = (error) => {
    setIsLoading(false);
    if (error?.response?.status == 401) useSessionExpired();
    else setPageError(dispatch, "An error occurred, please try again later");
  };

  const addUser = (item) => {
    const newUsers = [item, ...users];
    setUsers(newUsers);
  };

  const updateUser = (index, data) => {
    const newUsers = [...users];
    if (data && data.enabled) newUsers[index] = data;
    else newUsers.splice(index, 1);
    setUsers(newUsers);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <MDBox display="flex" justifyContent="center" alignItems="center" sx={{ height: 300 }}>
          <CircularProgress color="inherit" size={20} />
        </MDBox>
      ) : (
        <MDBox mt={3} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              {users && (
                <Users
                  data={users}
                  showForm={showForm}
                  isPermitted={isPermitted}
                  setShowForm={setShowForm}
                  setSelectedIndex={setSelectedIndex}
                  isLast={isLast}
                  pageSize={DATA_LIMIT}
                  totalCount={totalCount}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setSearchKeyword={setSearchKeyword}
                />
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              {isPermitted && showForm && (
                <Form
                  index={selectedIndex}
                  user={selectedUser}
                  onComplete={selectedUser ? updateUser : addUser}
                  setShowForm={setShowForm}
                  setSelectedIndex={setSelectedIndex}
                />
              )}
            </Grid>
          </Grid>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Accounts;
